import { Typography } from 'antd';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { useTicketingModal } from 'components/Ticketing/TicketingModalContext';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ITicket } from 'services/providers/TicketingProvider/types';
import '../../TicketingWidgetMigrated.less';
import TicketLabel from '../TicketLabel/TicketLabel';

const CardItemTicketing = ({
  item,
  onHover,
  onMouseLeave,
  isHovered,
  indexkey,
  ticketingPreview
}) => {
  const { Text } = Typography;
  const { theme } = useThemeContext();
  const { openFormTicketingModal } = useTicketingModal();
  const { isMobile } = useMobileContext();
  const ButtonCustom = BaseButton(ButtonSimple);
  const scope = item?.scope;

  const ticketsIsMoreThanFour = ticketingPreview.totalCount[scope] > 4;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
          paddingInline: '24px'
        }}
      >
        {isMobile ? (
          <div
            className={`ticketing__card__body  ${isHovered ? 'hovered' : ''}`}
            onMouseLeave={onMouseLeave}
            onMouseOver={onHover}
            data-item={indexkey}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 24,
                height: '100%'
              }}
            >
              <div className="body_icon_ctn" style={{ height: 'auto' }}>
                {item.icon}
              </div>
              <div className="text-icon">
                {' '}
                <Text
                  className="body_card_subtitle"
                  style={{ fontSize: '16px', lineHeight: 'normal' }}
                >
                  {item?.title}
                </Text>
              </div>
            </div>

            <div className="body_card_subtitle">
              {isHovered ? (
                <ButtonCustom
                  className={`text-bold-normal btn-primary-custom ${theme}`}
                  type="primary"
                  onClick={() =>
                    openFormTicketingModal(
                      item?.scope === 'Incident' ? 'incident' : 'service'
                    )
                  }
                  style={{ width: '100%' }}
                >
                  {item?.hoverButtonTitle}
                </ButtonCustom>
              ) : (
                <Text className="body_card_subtitle">{item?.title} </Text>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`ticketing__card__body  ${isHovered ? 'hovered' : ''}`}
            onMouseLeave={onMouseLeave}
            onMouseOver={onHover}
            data-item={indexkey}
            onClick={() =>
              openFormTicketingModal(
                item?.scope === 'Incident' ? 'incident' : 'service'
              )
            }
          >
            <div className="body_icon_ctn">{item.icon}</div>

            <div className="body_card_subtitle">
              {isHovered ? (
                <div className={`body_card_btn`}>{item?.hoverButtonTitle}</div>
              ) : (
                <Text className="body_card_subtitle ">{item?.title}</Text>
              )}
            </div>
          </div>
        )}

        <div className="ticketing__card__footer">
          <div className="footer_label_details">
            {ticketingPreview?.totalCount?.[scope] > 0 ? (
              <ul className="ticketing__list_details_labels">
                {ticketingPreview.tickets
                  .filter(item => item?.type === scope)
                  .slice(0, 4)
                  .map((item: ITicket) => {
                    const { ticketId, type, status, title } = item;
                    return (
                      <TicketLabel
                        key={ticketId}
                        ticketId={ticketId}
                        type={type}
                        status={status}
                        title={title}
                      />
                    );
                  })}
              </ul>
            ) : (
              <div className="not_available_text">
                <FormattedMessage id="Ticketing_None_Available" />
              </div>
            )}
          </div>
          <div style={{ opacity: ticketsIsMoreThanFour ? 1 : 0 }}>
            <Link
              to="/ticketing"
              state={[item?.scope]}
              onClick={e => {
                if (!ticketsIsMoreThanFour) e.preventDefault();
              }}
            >
              <Typography className={`typography_text ${theme}`}>
                <FormattedMessage id="Ticketing_View_All" />
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardItemTicketing;
