import { DateToUTC } from 'components/DateParser';
import { ClockInOutService } from 'services';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';
import dayjs from 'dayjs';
import { getSuccessMessages } from 'utils/getSuccessMessages';

export const onFinish = (data, actions) => {
  const formActions = { clockOut: ClockInOutService.clockOut };
  const { clockedInTime, clockOutTime, timeLoggedByEmployee, ...clockedOutData } =
    data;

  const [hours, minutes] = timeLoggedByEmployee.split(' ').map(time => Number(time.slice(0, -1)))
  const duration = dayjs.duration({ hours, minutes })

  clockedOutData.timeLoggedByEmployee = dayjs.utc(duration.asMilliseconds()).format('HH:mm:ss');
  clockedOutData.clockOutTime = DateToUTC(Date.now());

  onFormFinishWithPassedValidation(
    formActions,
    'clockOut',
    clockedOutData,
    actions,
    getSuccessMessages.success_clock_out
  );
};
