import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Button } from 'antd';
import ModalComponent from 'components/Modal';
import { useModalContext } from 'components/Modal/ModalProvider';
import { WidgetComponent } from 'components/Widgets';
import { TimeManagementWidget } from 'components/Widgets/TimeManagementWidget';
import EditProfilePage from 'pages/Profile/EditProfilePage';
import { FC, useState } from 'react';
import '../../Modal/ConfirmModal/ConfirmationModalComponent.less';
import { PapersWidget } from './PapersWidget';
import { ProfileUser } from './ProfileUserImage';
import { TeamWidget } from './TeamWidget';

// MOB Widget Carousel Imports
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './WidgetSlider.less';

import { BaseButton, ButtonSimple } from 'components/Buttons';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { MobileTimeManagementPlaceholder } from 'components/Placeholders';
import {
  SmallTimeManagementWidgetPlaceholder,
  TeamsWidgetPlaceholder,
  TimeManagementWidgetPlaceholder
} from 'components/Placeholders/Widgets';
import CalendarWidget from 'components/Widgets/CalendarWidget';
import { RequestManagement } from 'components/Widgets/Requests';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'services/providers/AuthProvider';
import { adminRoles, allowPermision, rolesAllow } from 'utils/userManagement';
import EmployeeInfoFieldsMobile from './EmployeeInfoFieldsMobile';
import './EmployeeProfileCard.less';
import { ProfileUserMobile } from './ProfileUserImage/profileUserImageMobile';
import TicketingWidgetMigrated from 'components/Widgets/TicketingWidgetMigrated';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type EmployeeProfileCardProps = {
  employeeId: string | number | { searchParamName: string; };
  data?: any;
  employeeTeamsData?: any;
  page?: string;
  scope?: string;
  employeeStatistics?: any;
};

const EmployeeProfileCard: FC<EmployeeProfileCardProps> = ({
  employeeId,
  data,
  employeeTeamsData,
  page,
  scope,
  employeeStatistics
}) => {
  const { toggleModal } = useModalContext();
  const { theme } = useThemeContext();
  const openModalDefault = {
    editProfile: false,
    clockInclockOut: false,
    request: false,
    viewDocuments: false
  };

  const employeeTeams = employeeTeamsData && [...employeeTeamsData];

  const [openModal, setModalOpen] = useState(openModalDefault);
  const openEditProfile = () => {
    setModalOpen({
      editProfile: true,
      clockInclockOut: false,
      request: false,
      viewDocuments: false
    });
    toggleModal();
  };

  const { user } = useAuth();
  const isAdminUserRole = allowPermision(user.role, adminRoles);

  const { isMobile, isTabletDevice, isSmallLaptop } = useMobileContext();

  const ButtonCustom = BaseButton(ButtonSimple);

  const intl = useIntl();
  const cancelBtnText = intl.formatMessage({ id: 'cancel' });
  const saveBtnText = intl.formatMessage({ id: 'save' });

  const classNameLoadingWidget = `ant-card card-widget ${theme}`;
  return (
    <>
      {isMobile ? (
        page === 'dashboard' ? (
          // - MOB Dashboard View -
          <Carousel
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            preventMovementUntilSwipeScrollTolerance
            swipeScrollTolerance={50}
            className="mobile-widget-carousel"
          >
            {/* first slide calendar widget */}
            <div className="mobile-carousel-item">
              <div
                className={`border-custom ${theme} gutter-row mobile-widget-height`}
              >
                <WidgetComponent
                  bordered={false}
                  className={`card-widget full-content calendar-widget-component ${theme}`}
                >
                  <CalendarWidget />
                </WidgetComponent>
              </div>
            </div>
            {/* second slide time off widget */}
            <div className="mobile-carousel-item time-managment-slide">
              <div
                className={`border-custom ${theme} gutter-row mobile-widget-height`}
              >
                <ComponentWithStatus
                  status={employeeStatistics ? 'success' : 'pending'}
                  Placeholder={<MobileTimeManagementPlaceholder />}
                >
                  <WidgetComponent
                    bordered={false}
                    className={`card-widget  ${theme} time-management-widget full-content`}
                  >
                    <TimeManagementWidget
                      scope={scope}
                      openModal={openModal}
                      setModalOpen={setModalOpen}
                      employeeStatistics={employeeStatistics}
                      size="size"
                    />
                  </WidgetComponent>
                </ComponentWithStatus>
              </div>
            </div>
            {/* third slide teams widget and papers widget or coming soon widget */}
            <div className="mobile-carousel-item">
              <div className="grid-container">
                <div className={`border-custom ${theme} gutter-row h-fifty`}>
                  <WidgetComponent
                    bordered={false}
                    className={`card-widget ${theme} team-widget h-full`}
                  >
                    <div
                      className={`card-widget ${theme} team-widget `}
                      style={{ height: '100%' }}
                    >
                      <TeamWidget
                        firstNameEmployee={data?.firstName}
                        data={employeeTeams}
                        scope={scope}
                        gender={data?.gender}
                      />
                    </div>
                  </WidgetComponent>
                </div>
                <div className={`border-custom ${theme} h-fifty`}>
                  <WidgetComponent
                    bordered={false}
                    center={!isAdminUserRole}
                    className={`card-widget h-full ${theme} ${isAdminUserRole && 'team-widget'
                      }`}
                  >
                    {isAdminUserRole ? (
                      <PapersWidget
                        firstNameEmployee={data?.firstName}
                        data={employeeTeams && employeeTeams}
                        key={employeeId as string}
                        scope={scope}
                        gender={data?.gender}
                        openModal={openModal}
                        setModalOpen={setModalOpen}
                        employeeId={employeeId}
                      />
                    ) : (
                      <FormattedMessage id="ComingSoonText" />
                    )}
                  </WidgetComponent>
                </div>
              </div>
            </div>
            {/* fourth slide ticketing widget */}
            <div className="mobile-carousel-item time-managment-slide">
              <div
                className={`border-custom ${theme} gutter-row mobile-widget-height`}
              >
                <ComponentWithStatus
                  status={employeeStatistics ? 'success' : 'pending'}
                  Placeholder={<MobileTimeManagementPlaceholder />}
                >
                  <WidgetComponent
                    bordered={false}
                    className={`card-widget  ${theme} time-management-widget full-content`}
                  >
                    <TicketingWidgetMigrated />
                  </WidgetComponent>
                </ComponentWithStatus>
              </div>
            </div>
          </Carousel>
        ) : (
          // ---End  MOB Dashboard View ---
          //  - MOB My Profile-
          <Carousel
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            swipeScrollTolerance={40}
            emulateTouch={false}
            preventMovementUntilSwipeScrollTolerance={true}
            className="mobile-widget-carousel"
          >
            {/* first slide edit profile widget */}
            <div className="mobile-carousel-item">
              <div
                className={`border-custom ${theme} gutter-row mobile-widget-height`}
              >
                {openModal.editProfile && (
                  <ModalComponent
                    isMaskClosable={false}
                    modalKey="editEmployeeModal"
                    formKeyId="employeeForm"
                    title={<FormattedMessage id="EditEmployeeModalTitle" />}
                    displayFooter
                    submitBtnText={saveBtnText}
                    cancelBtnText={cancelBtnText}
                    className="mobile-modal"
                    children={
                      <EditProfilePage
                        scope={scope === 'MyProfile' ? 'editMyProfile' : scope}
                        employeeId={employeeId}
                      />
                    }
                  />
                )}

                <WidgetComponent
                  className={`card-widget full-content mobile-version ${theme} gutter-row `}
                  bordered={false}
                  cover={
                    <ProfileUserMobile
                      scope={scope}
                      data={data}
                      employeeId={employeeId}
                    />
                  }
                  actions={[
                    <div className="edit-profile-button-block">
                      <Button
                        type="primary"
                        className={`btn-primary-custom ${theme}`}
                        onClick={openEditProfile}
                      >
                        <FormattedMessage id="editProfileText" />
                      </Button>
                    </div>
                  ]}
                >
                  <EmployeeInfoFieldsMobile data={data} />
                </WidgetComponent>
              </div>
            </div>

            {/* second slide teams widget and paers widget or comming soon widget */}
            <div className="mobile-carousel-item">
              <div className="grid-container">
                <div className={`border-custom ${theme} gutter-row h-fifty`}>
                  <WidgetComponent
                    bordered={false}
                    className={`card-widget ${theme} team-widget h-full`}
                  >
                    <div
                      className={`card-widget ${theme} team-widget `}
                      style={{ height: '100%' }}
                    >
                      <TeamWidget
                        firstNameEmployee={data?.firstName}
                        data={employeeTeams}
                        scope={scope}
                        gender={data?.gender}
                      />
                    </div>
                  </WidgetComponent>
                </div>
                <div className={`border-custom ${theme} h-fifty`}>
                  <WidgetComponent
                    bordered={false}
                    center={!isAdminUserRole}
                    className={`card-widget h-full ${theme} ${isAdminUserRole && 'team-widget'
                      }`}
                  >
                    {isAdminUserRole ? (
                      <PapersWidget
                        firstNameEmployee={data?.firstName}
                        data={employeeTeams && employeeTeams}
                        key={employeeId as string}
                        scope={scope}
                        gender={data?.gender}
                        openModal={openModal}
                        setModalOpen={setModalOpen}
                        employeeId={employeeId}
                      />
                    ) : (
                      <FormattedMessage id="ComingSoonText" />
                    )}
                  </WidgetComponent>
                </div>
              </div>
            </div>
            {/* third slide time off widget */}
            <div className="mobile-carousel-item time-managment-slide">
              <div
                className={`border-custom ${theme} gutter-row mobile-widget-height`}
              >
                <ComponentWithStatus
                  status={employeeStatistics ? 'success' : 'pending'}
                  Placeholder={<MobileTimeManagementPlaceholder />}
                >
                  <WidgetComponent
                    bordered={false}
                    className={`card-widget  ${theme} time-management-widget full-content`}
                  >
                    <TimeManagementWidget
                      scope={scope}
                      openModal={openModal}
                      setModalOpen={setModalOpen}
                      size="small"
                      employeeStatistics={employeeStatistics}
                    />
                  </WidgetComponent>
                </ComponentWithStatus>
              </div>{' '}
            </div>
            <div className="mobile-carousel-item">
              <div style={{ height: '100%' }}>
                {allowPermision(user.role, rolesAllow) ? (
                  <RequestManagement key="requestManagementComponent" />
                ) : (
                  <div className="full-content full-width ">
                    <WidgetComponent
                      center={true}
                      title={<FormattedMessage id="ComingSoonText" />}
                      bordered={false}
                      className={`full-content ${theme} full-width `}
                    />
                  </div>
                )}
              </div>{' '}
            </div>
          </Carousel>
          //--- End MOB My Profile ---
        )
      ) : (
        <div className="content-profile">
          {page === 'dashboard' && (
            //desktop view for Dashboard
            <>
              <div className="grid-container">
                <div className="half-content">
                  <div
                    className={` border-custom ${theme} large ${employeeStatistics ? '' : classNameLoadingWidget
                      }`}
                  >
                    <ComponentWithStatus
                      status={employeeStatistics ? 'success' : 'pending'}
                      Placeholder={<TimeManagementWidgetPlaceholder />}
                    >
                      <WidgetComponent
                        bordered={false}
                        className={`card-widget ${theme} time-management-widget full-content`}
                      >
                        <TimeManagementWidget
                          scope={scope}
                          employeeStatistics={employeeStatistics}
                          openModal={openModal}
                          setModalOpen={setModalOpen}
                          size="large"
                        />
                      </WidgetComponent>
                    </ComponentWithStatus>
                  </div>
                </div>
                <div
                  className="grid-row gap"
                  style={{ height: 'calc(50% - 16px)' }}
                >
                  <div className="grid-col">
                    <div
                      className={`border-custom ${theme} large w-full h-full`}
                    >
                      <WidgetComponent
                        bordered={false}
                        className={`card-widget full-content calendar-widget-component ${theme}`}
                      >
                        <CalendarWidget />
                      </WidgetComponent>
                    </div>
                  </div>
                  <div
                    className={`grid-col ${theme} `}
                    style={{ width: '25%', minWidth: '25%' }}
                  >
                    {' '}
                    <WidgetComponent
                      bordered={false}
                      bodyStyle={{
                        width: '100%',
                        height: '100%',
                        paddingBlock: '0',
                        padding: '0'
                      }}
                      className={`card-widget ${theme} w-full h-full`}
                    >
                      <TeamWidget
                        firstNameEmployee={data?.firstName}
                        data={employeeTeams}
                        scope={scope}
                        gender={data?.gender}
                      />
                    </WidgetComponent>
                  </div>
                  <div
                    className={`grid-col ${theme} `}
                    style={{ width: '25%', minWidth: '25%' }}
                  >
                    {' '}
                    <WidgetComponent
                      bordered={false}
                      bodyStyle={{
                        width: '100%',
                        height: '100%',
                        paddingBlock: '0',
                        padding: '0'
                      }}
                      className={`card-widget ${theme} w-full h-full`}
                    >
                      <TicketingWidgetMigrated />
                    </WidgetComponent>
                  </div>
                </div>
              </div>

              {/* Magic solution for user which have on laptop resolution 125% */}
              <div style={{ height: '16px' }}> </div>
            </>
            //--- end desktop view for Dashboard ---
          )}
          {page !== 'dashboard' && (
            //desktop view for MY PROFILE
            <>
              <>
                {' '}
                {openModal.editProfile && (
                  <ModalComponent
                    isMaskClosable={false}
                    modalKey="editEmployeeModal"
                    formKeyId="employeeForm"
                    title={<FormattedMessage id="EditEmployeeModalTitle" />}
                    displayFooter
                    submitBtnText={saveBtnText}
                    cancelBtnText={cancelBtnText}
                    children={
                      <EditProfilePage
                        scope={scope === 'MyProfile' ? 'editMyProfile' : scope}
                        employeeId={employeeId}
                      />
                    }
                  />
                )}
              </>
              <div className="row_class_conatiner ">
                <div
                  className="column_class_conatiner"
                  style={{ maxWidth: 'calc(50% - 8px)' }}
                >
                  <div
                    className="row_class"
                    style={{ minHeight: 'calc(50% - 8px)' }}
                  >
                    <div
                      className={`border-custom ${theme} gutter-row profile-widget height-full `}
                    >
                      <WidgetComponent
                        bordered={false}
                        className={`card-widget  wrapper-profile-col ${theme} full-content`}
                        cover={
                          <ProfileUser
                            data={data}
                            scope={scope}
                            employeeId={employeeId}
                          />
                        }
                        actions={[
                          <ButtonCustom
                            className={`text-bold-normal btn-primary-custom ${theme}`}
                            type="primary"
                            onClick={openEditProfile}
                          >
                            <FormattedMessage id="editProfileText" />
                          </ButtonCustom>
                        ]}
                      />
                    </div>
                  </div>
                  <div
                    className="grid-row gap"
                    style={{ maxHeight: 'calc(50% - 8px)' }}
                  >
                    <div
                      className={`grid-col ${theme} `}
                      style={{ width: 'calc(50% - 16px)' }}
                    >
                      {' '}
                      <WidgetComponent
                        bordered={false}
                        bodyStyle={{
                          width: '100%',
                          height: '100%',
                          paddingBlock: '0',
                          padding: '0'
                        }}
                        className={`card-widget ${theme} w-full h-full`}
                      >
                        <TeamWidget
                          firstNameEmployee={data?.firstName}
                          data={employeeTeams}
                          scope={scope}
                          gender={data?.gender}
                        />
                      </WidgetComponent>
                    </div>
                    <div
                      className={`border-custom ${theme} h-full`}
                      style={{ width: '50%' }}
                    >
                      <WidgetComponent
                        bordered={false}
                        center={!isAdminUserRole}
                        className={`card-widget h-full ${theme} ${isAdminUserRole && 'team-widget'
                          }`}
                      >
                        {isAdminUserRole ? (
                          <PapersWidget
                            firstNameEmployee={data?.firstName}
                            data={employeeTeams && employeeTeams}
                            key={employeeId as string}
                            scope={scope}
                            gender={data?.gender}
                            openModal={openModal}
                            setModalOpen={setModalOpen}
                            employeeId={employeeId}
                          />
                        ) : (
                          <FormattedMessage id="ComingSoonText" />
                        )}
                      </WidgetComponent>
                    </div>
                  </div>
                </div>
                <div className="column_class_conatiner max-width_widget">
                  <div
                    className={`row_class border-custom ${theme} gutter-row ${employeeStatistics ? '' : classNameLoadingWidget
                      }`}
                  >
                    {' '}
                    <ComponentWithStatus
                      status={employeeStatistics ? 'success' : 'pending'}
                      // status={'pending'}
                      Placeholder={<SmallTimeManagementWidgetPlaceholder />}
                    >
                      <WidgetComponent
                        bordered={false}
                        className={`card-widget ${theme} time-management-widget height-full my_profile_widget`}
                      >
                        <TimeManagementWidget
                          scope={scope}
                          employeeStatistics={employeeStatistics}
                          openModal={openModal}
                          setModalOpen={setModalOpen}
                          size="small"
                        />
                      </WidgetComponent>
                    </ComponentWithStatus>
                  </div>
                  <div
                    className={`row_class border-custom ${theme} gutter-row max-height_widget`}
                  >
                    {page !== 'dashboard' ? (
                      allowPermision(user.role, rolesAllow) &&
                        scope !== 'MyProfile' ? (
                        <RequestManagement key="requestManagementComponent" />
                      ) : (
                        <WidgetComponent
                          bordered={false}
                          className={`card-widget calendar-widget-component ${theme}`}
                        >
                          <CalendarWidget />
                        </WidgetComponent>
                      )
                    ) : (
                      <WidgetComponent
                        bordered={false}
                        className={`card-widget full-content calendar-widget-component ${theme}`}
                      >
                        <CalendarWidget />
                      </WidgetComponent>
                    )}
                  </div>
                </div>
              </div>
              {/* Magic solution for user which have on laptop resolution 125% */}
              <div style={{ height: '16px' }}> </div>
            </>
            //--- end desktop view for MY PROFILE ---
          )}
        </div>
      )}
    </>
  );
};

export default EmployeeProfileCard;
