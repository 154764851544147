export type IEmployee = {
  length?: boolean;
  currKey?: any;
  key?: React.Key;
  employeeId?: number;
  employeePersonalId?: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  gender?: IGender;
  email: string;
  birthDate: Date;
  phoneNumber: string;
  teams?: ITeams;
  department?: string;
  role: IRole;
  position: string;
  officialFunction: string;
  workingSchedule: IWorkingSchedule;
  contractType: IContractType;
  revokeDate?: Date;
  status: IStatus;
  startDate?: Date;
  endDate?: Date;
  dateAccountActivation?: Date;
  linkedAccount?: boolean;
  doorCardId: number;
  totalDaysAllowed?: number;
  signature?: boolean;
  externalAvatarUrl?: string;
  picture?: string;
};

export type IEmployeeImportFile = {
  failedRows?: Array<{
    rowNumber: string | number;
    errors: Record<
      | 'None'
      | 'LinkingFailed'
      | 'WrongUser'
      | 'TeamNotAssigned'
      | 'WrongRole'
      | 'UpdateFailed'
      | 'RoleOutOfRange'
      | 'RoleUpdateFailed'
      | 'DuplicateTeamAssignment'
      | 'EmployeeOrTeamNotFound'
      | 'AssignTeamFailed'
      | 'RemoveTeamFailed'
      | 'WrongPersonalId'
      | 'EndDateMandatory'
      | 'WrongContractType'
      | 'CreateFailed'
      | 'NoImportTemplate'
      | 'NoImportFile'
      | 'BadImportFile'
      | 'FieldValidation',
      string
    >;
  }>;
  failedRowsCount: number;
  successRowsCount: number;
};

type IStatisticType = {
  statisticType: string;
  totalDaysAllowed: number | null;
  daysTaken: number | null;
  daysLocked: number | null;
  daysLeft: number | null;
};

export type IEmployeeStatistics = {
  employeeId: number;
  statistics: IStatisticType[];
  enableClockIn: boolean;
  workingHours: string;
  lastClockIn: {
    actionId: number;
    employeeId: number;
    clockInTime: string;
    clockOutTime: string;
    timeLoggedByEmployee: string;
    notes: null | string;
  };
};

export type ITeams = {
  [teamId: number]: string;
};
export type ITeam = {
  dateCreated: string;
  department: string;
  description: string;
  isArchived: boolean;
  isHidden: boolean;
  leadEmployeeId: number;
  members: null | any[];
  name: string;
  teamId: number;
};

export type MyTeam = {
  teamId: number;
  name: string;
  leadEmployeeId: number;
  leadEmployeeName: string;
  description: string;
  department: string;
  membersCount: number;
};

enum IStatus {
  None = 'None',
  OnBoarding = 'OnBoarding',
  Internship = 'Internship',
  Employee = 'Employee',
  External = 'External',
  MaternityChildCareLeave = 'MaternityChildCareLeave',
}
//TO DO: do we really need to specify enum values here?
export enum IRole {
  None = 'None',
  Accountant = 'Accountant',
  Admin = 'Admin',
  Employee = 'Employee',
  External = 'External',
  HR = 'HR',
  Intern = 'Intern',
  TL = 'TL',
}

enum IGender {
  Male,
  Female,
}

enum IWorkingSchedule {
  FullTime,
  Hourly,
  PartTime,
  Shifts,
}

enum IContractType {
  LabourContractInfinite,
  LabourContractEndDate,
  Internship,
  CumulativeLabourContract,
  ServicesAgreement,
}
