import { FC, useEffect, useRef, useState } from 'react';
import { Form, Input, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import { useDispatch } from 'react-redux';
import { useModalContext } from 'components/Modal/ModalProvider';
import { onFinish } from './onFinish';
import TextArea from 'antd/es/input/TextArea';
import { useClockOutContext } from 'pages/TimeManagement/PersonalTimeOffRequests/PersonalTImeOffRequestsProvider';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { computeHoursAndMinutes } from '../../TimeOffRequestTable/utils';
import { setFormItemRules } from '../../../../UIComponents/Form/ColComponent/utils';
import { notifyWithError } from '../../../../../utils/notificationsUtils';
import './ClockOutForm.less';
type ClockOutFormProps = { paramsTimer };
type ValidationStatus = '' | 'error' | 'success' | 'warning' | 'validating';

const ClockOutForm: FC<ClockOutFormProps> = ({ paramsTimer }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { toggleModal } = useModalContext();
  const { clockOutProps, setClockOutProps } = useClockOutContext();
  const [isUserSettingTime, setIsUserSettingTime] = useState<boolean>(false);
  const [hoursInputValue, setHoursInputValue] = useState<string>(null);
  const [minutesInputValue, setMinutesInputValue] = useState<string>(null);
  const [clockedIn, setClockedIn] = useState<any>(clockOutProps.clockedIn);
  const clockOutLabelRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(320);
  const [customErrorValidationMessage, setCustomErrorValidationMessage] =
    useState(null);
  const [clockInClokOutValidationStatus, setClockInClockOutValidationStatus] =
    useState<{ clockIn: string; clockOut: string }>({
      clockIn: 'success',
      clockOut: 'success'
    });
  const { isMobile } = useMobileContext();

  const { theme } = useThemeContext();
  const intl = useIntl();

  const handleClockInChange = e => {
    if (!isUserSettingTime) {
      setIsUserSettingTime(true);
    }
    handleValidationForClockInClockOut();
    setClockedIn(e);
    setClockOutProps({
      clockedIn: e ? e.toDate() : clockOutProps.clockedIn,
      ...clockOutProps
    });
  };

  const handleClockutChange = e => {
    if (!isUserSettingTime) {
      setIsUserSettingTime(true);
    }
    handleValidationForClockInClockOut();
    setClockOutProps({
      clockedOut: e ? e.toDate() : clockOutProps.clockedOut,
      ...clockOutProps
    });
  };

  useEffect(() => {
    const INPUT_WIDTH = 105;
    const LABEL_PADDING = 24;

    setInputWidth(
      clockOutLabelRef.current.offsetWidth + LABEL_PADDING * 2 + INPUT_WIDTH * 2
    );
  }, [clockOutLabelRef]);

  const handleValidationForClockInClockOut = () => {
    const clockInValue = form.getFieldValue('clockedInTime');
    const clockOutValue = form.getFieldValue('clockOutTime');

    const clockInTime = dayjs(clockInValue, 'HH:mm');
    const clockOutTime = dayjs(clockOutValue, 'HH:mm');
    const diff = clockOutTime.diff(clockInTime);

    let errorMessageId;
    let clockInStatus = 'success';
    let clockOutStatus = 'success';

    if (clockInValue === null && clockOutValue === null) {
      errorMessageId = 'ClockOutFormClockInAndClockOutValidation';
      clockInStatus = clockOutStatus = 'error';
    } else if (clockInValue === null) {
      errorMessageId = 'ClockOutFormClockInValidation';
      clockInStatus = 'error';
    } else if (clockOutValue === null) {
      errorMessageId = 'ClockOutFormClockOutValidation';
      clockOutStatus = 'error';
    } else if (diff < 0) {
      errorMessageId = 'ClockInClockOutTimeCompareValidation';
      clockInStatus = 'error';
    }

    if (errorMessageId) {
      setClockInClockOutValidationStatus({
        clockIn: clockInStatus,
        clockOut: clockOutStatus
      });
      setCustomErrorValidationMessage(
        intl.formatMessage({ id: errorMessageId })
      );
      notifyWithError({
        message: intl.formatMessage({ id: 'ValidationFailedErrorMessage' }),
        description: intl.formatMessage({ id: errorMessageId })
      });
      return true;
    }

    setCustomErrorValidationMessage(null);
    setClockInClockOutValidationStatus({
      clockIn: 'success',
      clockOut: 'success'
    });
    return false;
  };

  const shouldValidateClockInClockOut =
    !isMobile && isUserSettingTime && handleValidationForClockInClockOut;

  useEffect(() => {
    if (isUserSettingTime) {
      const record = {
        endDate: form.getFieldValue(`clockOutTime`),
        startDate: form.getFieldValue(`clockedInTime`)
      };

      form.setFieldsValue({
        clockOutTime: form.getFieldValue(`clockOutTime`),
        timeLoggedByEmployee:
          clockInClokOutValidationStatus.clockIn === 'success' &&
          clockInClokOutValidationStatus.clockOut === 'success'
            ? computeHoursAndMinutes(record)
            : null
      });
    } else {
      form.setFieldValue(
        'clockedInTime',
        dayjs.tz(dayjs.utc(clockOutProps.clockedIn), dayjs.tz.guess())
      );
      form.setFieldsValue({
        clockOutTime: paramsTimer.clockedOut,
        timeLoggedByEmployee:
          paramsTimer.clockInOutHoursSpentDiff +
          'h ' +
          paramsTimer.clockInOutRemainedMinutesDiff +
          'm'
      });
    }
  }, [
    paramsTimer.clockedOut,
    paramsTimer.clockInOutRemainedMinutesDiff,
    clockedIn,
    clockOutProps
  ]);

  return (
    <Form
      form={form}
      className={'clockoutForm'}
      id="clockoutForm"
      name="clockoutForm"
      layout="horizontal"
      labelAlign="left"
      colon={false}
      labelCol={{ span: 8 }}
      labelWrap
      onFinish={data => {
        if (!isMobile && handleValidationForClockInClockOut()) return;

        const actions = {
          dispatch,
          toggleModal,
          triggerOtherActionsWithResult: () =>
            setClockOutProps({
              ...clockOutProps,
              isClockedIn: false,
              clockedOut: paramsTimer.clockedOut,
              isClockedOut: true,
              timeWorked: {
                hours: paramsTimer.timeClocked.hours,
                minutes: paramsTimer.timeClocked.minutes
              }
            })
        };
        onFinish(data, actions);
      }}
      onFinishFailed={validationError =>
        onFormFinishFailedValidation(validationError, dispatch)
      }
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: !isMobile ? 'auto auto' : '1fr',
          justifyContent: 'space-between',
          columnGap: '0px',
          rowGap: '16px'
        }}
      >
        <ColFormItemComponent
          style={{
            width: !isMobile && '211px',
            marginBottom: isMobile && '16px'
          }}
          id="clockIn"
          validateStatus={
            clockInClokOutValidationStatus.clockIn as
              | ''
              | 'error'
              | 'success'
              | 'warning'
              | 'validating'
          }
          span={12}
          label={<FormattedMessage id="In" />}
          name="clockedInTime"
          dependencies={['clockOutTime']}
          rules={
            isMobile
              ? [
                  ...setFormItemRules({
                    isRequired: true,
                    requiredMessage: intl.formatMessage({
                      id: 'ClockOutFormClockInValidation'
                    })
                  }),
                  {
                    validator: (_, value) => {
                      const clockIn = dayjs(
                        form.getFieldValue('clockedInTime'),
                        { format: 'HH:mm' }
                      );
                      const clockOut = dayjs(
                        form.getFieldValue('clockOutTime'),
                        { format: 'HH:mm' }
                      );

                      const diff = clockOut.diff(clockIn);

                      return diff < 0
                        ? Promise.reject(
                            new Error(
                              intl.formatMessage({
                                id: 'ClockInClockOutTimeCompareValidation'
                              })
                            )
                          )
                        : Promise.resolve();
                    }
                  }
                ]
              : []
          }
        >
          <TimePicker
            style={{ width: isMobile ? '100%' : '' }}
            suffixIcon={null}
            format="HH:mm"
            onChange={handleClockInChange}
          />
        </ColFormItemComponent>
        <ColFormItemComponent
          span={12}
          label={
            <p ref={clockOutLabelRef}>
              <FormattedMessage id="Out" />
            </p>
          }
          name="clockOutTime"
          id={'clockOut'}
          dependencies={['clockedInTime']}
          validateStatus={
            clockInClokOutValidationStatus.clockOut as ValidationStatus
          }
          rules={
            isMobile
              ? [
                  ...setFormItemRules({
                    isRequired: true,
                    requiredMessage: intl.formatMessage({
                      id: 'ClockOutFormClockOutValidation'
                    })
                  })
                ]
              : []
          }
        >
          <TimePicker
            style={{ width: isMobile ? '100%' : '' }}
            suffixIcon={null}
            format="HH:mm"
            onChange={handleClockutChange}
          />
        </ColFormItemComponent>
      </div>
      {shouldValidateClockInClockOut && (
        <span
          className={'custom-validation-error'}
          style={{ display: customErrorValidationMessage ? 'inline' : 'none' }}
        >
          {customErrorValidationMessage}
        </span>
      )}
      <ColFormItemComponent
        span={24}
        name="timeLoggedByEmployee"
        label={<FormattedMessage id="TimeClocked" />}
      >
        <Input
          readOnly={true}
          style={{ paddingRight: '0.5em', width: isMobile ? '' : inputWidth }}
          value={
            isUserSettingTime
              ? minutesInputValue
              : paramsTimer.timeClocked.minutes
          }
          addonAfter=""
          onChange={e => {
            if (!isUserSettingTime) {
              setIsUserSettingTime(true);
            }
            setHoursInputValue(e.currentTarget.value);
          }}
        />
      </ColFormItemComponent>

      <ColFormItemComponent
        span={24}
        label={<FormattedMessage id="Notes" />}
        name="notes"
        rules={setFormItemRules({
          isRequired: isUserSettingTime,
          requiredMessage: intl.formatMessage({
            id: 'ClockOutFormNoteValidation'
          })
        })}
      >
        <TextArea
          style={{ paddingRight: '0.5em', minWidth:  isMobile ? '' : inputWidth }}
          maxLength={500}
        />
      </ColFormItemComponent>
    </Form>
  );
};

export default ClockOutForm;
