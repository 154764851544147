import { EditTeamForm } from 'components/Team/TeamTable/verticalTabel';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useModalContext } from 'components/Modal/ModalProvider';
import { adminRoles, allowPermision, rolesAllow } from 'utils/userManagement';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import LayoutPageMobileComponent from 'components/LayoutPageWithTitle.tsx/LayoutPageMobileComponent';
import { useTeamViewContext } from 'components/Team/TeamViewContext/TeamViewContext';
import { FormattedMessage } from 'react-intl';
import { EditIconSimpleSvg } from 'Icons/EditIconSimple';
import { AddUserSvg } from 'Icons/addUserComponent';

export const TeamDetails = props => {
  const { role, setEditable, onSaveDetails, onCancelEditDetails, setIsAddMemberModal } = props;
  const { toggleModal } = useModalContext();
  const navigate = useNavigate();
  const isAdminUserRole = allowPermision(role, rolesAllow);
  const [editTeam, setEditTeam] = useState<boolean>(isAdminUserRole);
  const toggleEditTeamFields = () => setEditTeam(!editTeam);
  const editTeamFields = () => {
    setEditable(true);
    toggleEditTeamFields();
  };
  const updateTeamFields = () => {
    onSaveDetails();
    toggleEditTeamFields();
  };

  const cancelEditTeamFields = () => {
    onCancelEditDetails();
    toggleEditTeamFields();
  };
  const ButtonComponent = BaseButton(ButtonSimple);
  const { theme } = useThemeContext();

  const { isMobile } = useMobileContext();

  const { displayMembers, setDisplayMembers } = useTeamViewContext();

  const { state } = useLocation();

  const handleClick = () => {
    if (displayMembers) {
      setDisplayMembers(false);
    } else if (state === 'fromTeams') {
      navigate('/teams');
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        displayToolbarButtons={!isMobile}
        className="padding-search"
        children={
          <>
            <ButtonComponent
              className={`text-bold-normal btn-default-custom ${theme}`}
              onClick={handleClick}
            >
              <FormattedMessage id="back" />
            </ButtonComponent>
            {allowPermision(role, adminRoles) && isMobile && (
              <div className="toolbar-top-role-element">
                <LayoutPageMobileComponent />
              </div>
            )}
          </>
        }
        buttons={[
          isAdminUserRole && {
            text: <FormattedMessage id="save" />,
            type: 'primary',
            className: `text-bold-normal btn-primary-custom ${theme}`,
            action: updateTeamFields,
            show: !editTeam
          },
          isAdminUserRole && {
            text: <FormattedMessage id="cancel" />,
            action: cancelEditTeamFields,
            show: !editTeam
          },
          allowPermision(role, adminRoles) && {
            icon: <EditIconSimpleSvg />,
            text: (
              <span>
                <FormattedMessage id="EditTeam" />
              </span>
            ),
            action: editTeamFields,
            show: editTeam
          },
          allowPermision(role, adminRoles) && {
            icon: <AddUserSvg />,
            text: (
              <span>
                {' '}
                <FormattedMessage id="AddMember" />
              </span>
            ),
            action: () => {
              setIsAddMemberModal(true)
              toggleModal();
              setEditable(false);
            },
            show: true
          }
        ]}
      />
      <EditTeamForm {...props} />
    </>
  );
};
