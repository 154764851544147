import {
  useIntlContext,
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { Col, Row, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CalendarHeadingDateToggle } from './CalendarHeadingDateToggle';
import EventsList from './EventsList';
import './calendarPage.less';

import { CalendarFilled } from '@ant-design/icons';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { convertToUTC } from 'components/DateParser';
import StyledCalendar from 'components/StyledCalendar';
import { FormattedMessage, useIntl } from 'react-intl';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import { useCalendarModal } from '../CalendarModalContext';
import { EventTypes, EventsViewType } from '../types';
import {
  CalendarDayViewSkeleton,
  CalendarSkeleton,
  CalendarWeekViewSkeleton
} from './Skeletons/CalendarSkeleton';
import { DateTogglerSkeleton } from './Skeletons/DateTogglerSkeleton';
import { EventListSkeleton } from './Skeletons/EventListSkeleton';
import { useCalendarEvents } from './hooks';
import {
  SafeFormattedMessage,
  useSafeIntl,
  useTranslationExistenceChecker
} from '../../../components/UIComponents/SafeFormattedMessage';

interface IEventsCalendarType {
  viewPeriodMode: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth';
  eventViewType: EventsViewType;
  eventType: EventTypes;
}

/** Implemented using FullCalendar API
 * @link https://fullcalendar.io/
 *
 * This FullCalendar API is framework and theme agnostic, therefore the React implementation sometimes requires access to the underlying VanillaJS implementation methods.
 *
 * On first render, initialize the CalendarAPI in a ref to preserve it during re-renders.
 * Make calls to the CalendarAPI methods by checking it for null first.
 *
 * @see https://fullcalendar.io/doc
 */
const EventsCalendarPage: React.FC<IEventsCalendarType> = ({
  viewPeriodMode,
  eventViewType,
  eventType
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    dayjs(dayjs().format('YYYY-MM-DDT00:00:00'))
  );
  const translationChecker = useTranslationExistenceChecker();
  const { openFormCalendarModal, openViewEventsPerDayModal } =
    useCalendarModal();
  const isDayOrWeekView = ['timeGridWeek', 'timeGridDay'].includes(
    viewPeriodMode
  );
  const { eventsData, calendarDaysData, status } = useCalendarEvents(
    selectedDate,
    eventType,
    eventViewType,
    viewPeriodMode
  );
  const { theme } = useThemeContext();
  const { locale } = useIntlContext();
  const { isMobile, isTabletDevice } = useMobileContext();
  const intl = useIntl();

  const calendarRef = useRef(null);
  let CalendarAPI = useRef(null); //store CalendarAPI object in ref to preserve value accross renderings

  const calendarWrapperRef = useRef<HTMLDivElement>(null);

  const prevSelectedCell = useRef(null);
  const eventsPerDay = calendarDaysData as any;

  useEffect(() => {
    dayjs.extend(dayLocaleData);
    setSelectedDate(selectedDate.locale(locale));
  }, [locale]);

  //initialize calendar API to access inner methods
  useLayoutEffect(() => {
    CalendarAPI.current = calendarRef?.current?.getApi();
  }, [calendarRef.current]);

  //change calendar view
  useEffect(() => {
    CalendarAPI.current?.changeView(viewPeriodMode);
    CalendarAPI.current?.render();
    CalendarAPI.current?.select(CalendarAPI.current?.getDate()); //set selection to current date
  }, [viewPeriodMode]);

  useEffect(() => {
    //sync calendar API's date to selectedDate state
    CalendarAPI.current?.gotoDate(selectedDate.toDate());
    CalendarAPI.current?.select(selectedDate.toDate());
  }, [selectedDate]);

  //on each render, select the current date
  useEffect(() => {
    CalendarAPI.current?.select(CalendarAPI.current?.getDate()); //set selection to current date
  });
  useEffect(() => {
    // toggles highlight class to the relevant cell header in day and week view, which is currently selected
    //we do this via direct DOM update since we don't have content injection API for it
    const currentHighlightedDateValue = (
      document.querySelector(
        '.calendar-main td[role="gridcell"]:has(.fc-highlight)'
      ) as HTMLElement
    )?.dataset?.date;

    document.querySelector('.fc-day-today')?.classList.add('highlight');

    const highlightedHeaderCell =
      ['timeGridDay', 'timeGridWeek'].includes(viewPeriodMode) &&
      (document.querySelector(
        'th[data-date="' + currentHighlightedDateValue + '"]'
      ) as HTMLElement);

    if (prevSelectedCell?.current?.classList?.contains('highlight')) {
      !prevSelectedCell?.current?.classList?.contains('fc-day-today') &&
        prevSelectedCell?.current?.classList?.remove('highlight');
    }

    if (!highlightedHeaderCell?.classList?.contains('highlight')) {
      highlightedHeaderCell?.classList?.add('highlight');
    }

    prevSelectedCell.current = highlightedHeaderCell;
  });

  return (
    <Row className={`calendar-main ${viewPeriodMode} ${theme}`} gutter={48}>
      <Col
        xl={18}
        lg={24}
        xs={24}
        className="with-divider"
        ref={calendarWrapperRef}
        style={{
          paddingRight: '17px'
        }}
      >
        {/*  Month toggler  */}
        {status.calendarEvents === 'pending' ? (
          <DateTogglerSkeleton />
        ) : (
          <CalendarHeadingDateToggle
            leftArrowClick={() => {
              setSelectedDate(prev =>
                dayjs(CalendarAPI.current?.getDate() ?? prev)
                  .subtract(1, 'month')
                  .set('date', 1)
              );
            }}
            rightArrowClick={() => {
              setSelectedDate(prev =>
                dayjs(CalendarAPI?.current?.getDate() ?? prev)
                  .add(1, 'month')
                  .set('date', 1)
              );
            }}
            text={selectedDate.format('MMMM') + ', ' + selectedDate.year()}
          />
        )}

        <div className="calendar-wrapper">
          {status.calendarEvents === 'pending' ? (
            viewPeriodMode === 'dayGridMonth' ? (
              <CalendarSkeleton
                headercellheight={isMobile ? 15 : 30}
                calendarcellwidth={
                  calendarWrapperRef.current
                    ? calendarWrapperRef.current.scrollWidth / 7 - 5 // 7 - days, 5 - gutter
                    : 120
                }
                calendarcellheight={isMobile ? 50 : 100}
                gutter={5}
                borderradius={isMobile ? 5 : 10}
              />
            ) : viewPeriodMode === 'timeGridDay' ? (
              <CalendarDayViewSkeleton calendarcellwidth={'98%'} />
            ) : (
              <CalendarWeekViewSkeleton
                borderradius={'0'}
                calendarcellheight={50}
              />
            )
          ) : (
            <FullCalendar
              initialDate={selectedDate.toDate()}
              initialView={viewPeriodMode}
              ref={calendarRef}
              viewDidMount={arg => {
                //setting the timezone in first header cell in week view
                if (['timeGridWeek'].includes(arg.view.type)) {
                  const timezoneValue = Math.floor(
                    -dayjs().toDate().getTimezoneOffset() / 60
                  );
                  //setting via inner HTML is the only way to reach this DOM element
                  arg.el.querySelector('.fc-timegrid-axis-frame').textContent =
                    'GMT +' +
                    (timezoneValue > 12 ? timezoneValue : '0' + timezoneValue);
                }
              }}
              aspectRatio={3}
              expandRows={false}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: '',
                center: '',
                right: ''
              }}
              eventClick={info => {
                openFormCalendarModal(
                  'view',
                  info.event._def?.extendedProps?.eventObj,
                  convertToUTC(dayjs(info.event._instance.range.start))
                );
              }}
              // event
              weekends={true}
              firstDay={1}
              scrollTime={dayjs().format('HH:mm:ss')}
              slotDuration={'01:00:00'}
              slotEventOverlap={false}
              eventOrder={'-isTimeOffEvent,start,title'} //timeoff events should be on top of the events list
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: false,
                hour12: false
              }}
              nowIndicator={true}
              nowIndicatorContent={arg => (
                <>{arg.isAxis && <p>{dayjs(arg.date).format('HH:mm')}</p>} </>
              )}
              dateClick={dateClickArg => {
                setSelectedDate(dayjs(dateClickArg.date).set('hours', 0));
              }}
              selectable={viewPeriodMode === 'dayGridMonth'}
              selectAllow={({ start, end }) => {
                //restrict selection to one day only
                return (
                  dayjs(start).format('YYYY-MM-DDT00:00:00') ===
                  dayjs(end).add(-1).format('YYYY-MM-DDT00:00:00')
                );
              }}
              locale={locale}
              dayMaxEventRows={viewPeriodMode === 'dayGridMonth' ? true : 4}
              moreLinkText={intl.formatMessage({
                id: 'moreEvents'
              })}
              moreLinkContent={
                isMobile
                  ? arg => {
                      return (
                        <>
                          {arg.shortText} <CalendarFilled />
                        </>
                      );
                    }
                  : undefined
              }
              events={eventsData}
              dayHeaderContent={args => {
                const defaultFCDayHeaderText = args.text
                  .match(/[a-zA-ZAĂÂÎaăîâ]/g)
                  ?.join('')
                  ?.toString();

                if (args.view.type === 'timeGridWeek') {
                  return (
                    <div
                      onClick={() => {
                        setSelectedDate(dayjs(args.date).set('hours', 0));
                      }}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <span>
                        {capitalizeFirstLetter(defaultFCDayHeaderText)}
                      </span>
                      <span>{dayjs(args.date).get('date')}</span>
                    </div>
                  );
                }

                if (args.view.type === 'dayGridMonth') {
                  return (
                    <div>
                      <span>
                        {capitalizeFirstLetter(defaultFCDayHeaderText)}
                      </span>
                    </div>
                  );
                }

                return args.text;
              }}
              dayCellClassNames={['cell-borders', 'cell-hover']}
              dayHeaderClassNames={['cell-borders']}
              slotLabelClassNames={['allDay-slot']}
              slotLabelContent={arg => dayjs(arg.date).format('HH:mm')}
              allDayClassNames={['allDay-slot']}
              allDayContent={() => (
                <div className="allDay-timezone-container">
                  <span>GMT</span>
                  <span>
                    {(() => {
                      const timezoneValue = Math.floor(
                        -dayjs().toDate().getTimezoneOffset() / 60
                      );

                      if (timezoneValue < 0) {
                        if (timezoneValue > -10) {
                          return '-0' + Math.abs(timezoneValue);
                        } else return timezoneValue;
                      } else if (timezoneValue < 10) {
                        return '+0' + timezoneValue;
                      } else return '+' + timezoneValue;
                    })()}
                  </span>
                </div>
              )}
              //prevent unselecting the date when clicking outside of the calendar
              unselectAuto={false}
              navLinks={false}
              moreLinkClick={
                isMobile || isTabletDevice
                  ? info => {
                      openViewEventsPerDayModal(
                        dayjs(info?.date),
                        calendarDaysData
                      );

                      return 'month';
                    }
                  : undefined
              }
              dayPopoverFormat={
                //hide default popover element on mobiles
                isMobile || isTabletDevice
                  ? () => {
                      return null;
                    }
                  : { month: 'long', day: 'numeric', year: 'numeric' }
              }
            />
          )}
        </div>
      </Col>
      {!isTabletDevice && !isMobile && (
        <Col xl={6} xs={24} className="eventsPerDay-wrapper">
          <Row justify={'center'}>
            <Col>
              {/*  Day toggler  */}
              {status.calendarDaysData === 'pending' ? (
                <DateTogglerSkeleton />
              ) : (
                <CalendarHeadingDateToggle
                  leftArrowClick={() =>
                    setSelectedDate(current =>
                      dayjs(
                        current.subtract(1, 'day').format('YYYY-MM-DDT00:00:00')
                      )
                    )
                  }
                  rightArrowClick={() =>
                    setSelectedDate(current =>
                      dayjs(current.add(1, 'day').format('YYYY-MM-DDT00:00:00'))
                    )
                  }
                  text={
                    selectedDate.format('dddd') + ' ' + selectedDate.format('D')
                  }
                />
              )}
            </Col>
          </Row>
          <Row className="events-section-container">
            {isDayOrWeekView && (
              <Col>
                <StyledCalendar
                  fullscreen={false}
                  className={
                    viewPeriodMode === 'timeGridWeek'
                      ? `week-highlight ${theme}`
                      : viewPeriodMode === 'timeGridDay'
                        ? `day-highlight ${theme}`
                        : ''
                  }
                  headerRender={() => <></>}
                  onSelect={e => {
                    setSelectedDate(dayjs(e));
                  }}
                  value={selectedDate}
                />
              </Col>
            )}
            <Col xs={24}>
              {status.calendarDaysData === 'pending' ? (
                <EventListSkeleton style={{ margin: '32px 0' }} />
              ) : eventsPerDay?.length > 0 ? (
                <EventsList data={(eventsPerDay as any) ?? []} />
              ) : (
                <div className="no-events-text-container">
                  <Typography.Title level={4}>
                    <FormattedMessage id="noEventsText" />
                  </Typography.Title>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};
export default EventsCalendarPage;
