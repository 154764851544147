import { PlusCircleOutlined } from '@ant-design/icons';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import ModalComponent from 'components/Modal';
import { useModalContext } from 'components/Modal/ModalProvider';
import { TableComponentPlaceholder } from 'components/Placeholders';
import { WorkQueueConfigurationForm } from 'components/SetitingsComponents/WorkQueue/WorkQueueConfigurationForm';
import { WorkerForm } from 'components/SetitingsComponents/WorkQueue/WorkerForm';
import TableComponent from 'components/Table';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useConfigurationsTableColumns } from './useConfigurationsTableColumns';
import { useWorkersTableColumns } from './useWorkersTableColumns';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { TicketingWorkQueueService } from 'services';
import { WorkQueue } from './types';

let deleteModalWidth: string;
let editModalWidth: string;
let addMoldalWidth: string;

const categoryWidth = () => {
  if (window.innerWidth <= 768) {
    editModalWidth = '100%';
    addMoldalWidth = '100%';
    deleteModalWidth = '100%';
  } else {
    editModalWidth = '35%';
    addMoldalWidth = '45%';
    deleteModalWidth = '20%';
  }
};

export const ViewWorkQueue = () => {
  const intl = useIntl();
  const { deviceHeight, isMobile, deviceOS } = useMobileContext();
  // get from url params queueId /settings/viewWorkQueue?queueId=20
  const [URLParams] = useSearchParams();
  const queueIdFromURL = URLParams.get('queueId');
  const ButtonComponent = BaseButton(ButtonSimple);
  const [totalRowsWorkers, setTotalRowsWorkers] = useState(0);
  const [totalRowsConfigurations, setTotalRowsConfigurations] = useState(0);

  const { theme } = useThemeContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { data, status } = useApiRequestHook<WorkQueue>(
    TicketingWorkQueueService.getWorkQueueById,
    queueIdFromURL,
    [],
    {}
  );

  const [modalWidth, setModalWidth] = useState<string>();
  const {
    isModalOpen,
    toggleModal,
    setIsModalOpen,
    setIsTopRoleModalOpen,
    isTopRoleModalOpen
  } = useModalContext();

  const [modalProps, setModalProps] = useState({
    key: null,
    scope: 'add',
    formKeyId: null,
    modalTitle: null,
    workQueueId: null,
    workerId: null,
    submitButtonText: <FormattedMessage id="submit" />,
    cancelButtonText: <FormattedMessage id="cancel" />,
    width: modalWidth,
    children: null
  });

  const configurationsTableColumnsArray = useConfigurationsTableColumns(
    setIsModalOpen,
    setModalProps,
    editModalWidth,
    deleteModalWidth,
    Number(queueIdFromURL)
  );
  const workersTableColumnsArray = useWorkersTableColumns(
    setIsModalOpen,
    setModalProps,
    editModalWidth,
    deleteModalWidth,
    Number(queueIdFromURL)
  );

  useEffect(() => {
    if (isMobile) {
      setModalWidth('100%');
    } else {
      setModalWidth('45%');
    }
    categoryWidth();
  }, [isMobile]);

  const handleClick = () => {
    if (state === '/settings/viewWorkQueue') {
      navigate('/settings/workQueueTicketing');
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="configurationContainer">
      <ModalComponent
        key={modalProps.key}
        title={modalProps.modalTitle}
        submitBtnText={modalProps.submitButtonText}
        displayFooterSubmitButton={true}
        cancelBtnText={modalProps.cancelButtonText}
        formKeyId={modalProps.formKeyId}
        width={modalProps.width}
        className='modal_without_effects_select'
      >
        {modalProps.children}
      </ModalComponent>
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        className="padding-search"
        buttons={[
          {
            icon: <PlusCircleOutlined />,
            text: (
              <span>
                <FormattedMessage id="add_configuration" />
              </span>
            ),
            action: () => {
              setModalProps(state => ({
                ...state,
                categoryId: null,
                key: 'workQueueConfigurationFormModal',
                submitButtonText: <FormattedMessage id="add" />,
                cancelButtonText: <FormattedMessage id="close" />,
                modalTitle: <FormattedMessage id="Settings_WorkQueues_Add_Configuration" />,
                width: addMoldalWidth,
                formKeyId: 'workQueueConfigurationForm',
                children: (
                  <WorkQueueConfigurationForm
                    scope={'add'}
                    workQueueId={Number(queueIdFromURL)}
                  />
                )
              }));
              toggleModal();
            },
            show: true
          },
          {
            icon: <PlusCircleOutlined />,
            text: (
              <span>
                {intl.formatMessage({ id: "Settings_WorkQueues_Add_Worker" }).toLowerCase()}
              </span>
            ),
            action: () => {
              setModalProps(state => ({
                ...state,
                scope: 'add',
                key: 'workerFormModal',
                categoryId: null,
                formKeyId: 'workerForm',
                submitButtonText: <FormattedMessage id="add" />,
                cancelButtonText: <FormattedMessage id="close" />,
                modalTitle: <FormattedMessage id="Settings_WorkQueues_Add_Worker" />,
                width: addMoldalWidth,
                children: (
                  <WorkerForm
                    scope={'add'}
                    queueId={Number(queueIdFromURL)}
                    workers={data?.workers}
                  />
                )
              }));
              toggleModal();
            },
            show: true
          }
        ]}
        children={
          <>
            {' '}
            <ButtonComponent
              className={`text-bold-normal btn-default-custom ${theme}`}
              onClick={handleClick}
            >
              <FormattedMessage id="back" />
            </ButtonComponent>
          </>
        }
      />
      <br />
      <TableLayoutPage
        title={
          <FormattedMessage
            id="Settings_WorkQueues_WorkersWithData"
            values={{ totalRowsWorkers }}
          />
        }
        renderCustomButton
        displayToggle
        style={{
          height: `calc((${deviceHeight}px - 202px) / 2)`,
          overflow: 'hidden'
        }}
      >
        <ComponentWithStatus
          ignoreAnimation={true}
          status={status}
          Placeholder={<TableComponentPlaceholder count={4} />}
        >
          <TableComponent
            showSorterTooltip={false}
            tableSize="large"
            style={{
              height: `calc((${deviceHeight}px - 22em ) / 2)`,
              minHeight: `calc((${deviceHeight}px - 22em) / 2 )`
            }}
            scroll={{
              x: 'calc((100vw - 213px) / 2)',
              y: isMobile
                ? deviceOS === 'IOS'
                  ? 'calc((100vh - 30.8em ) / 2)'
                  : 'calc((100vh - 28.8em) /2)'
                : 'calc((100vh - 32.5em) /2)'
            }}
            rowKey={'employeeId'}
            className="workers_settings_table"
            rowClassName={'hoverRow'}
            dataSource={data?.workers}
            data={data?.workers}
            columns={() => workersTableColumnsArray}
            setTotalRows={setTotalRowsWorkers}
          />
        </ComponentWithStatus>
      </TableLayoutPage>
      <br />
      <TableLayoutPage
        title={
          <FormattedMessage
            id="Settings_WorkQueues_configurationsWithData"
            values={{ totalRowsConfigurations }}
          />
        }
        renderCustomButton
        displayToggle
        style={{
          height: `calc((${deviceHeight}px - 202px ) / 2)`,
          overflow: 'hidden'
        }}
      >
        <ComponentWithStatus
          ignoreAnimation={true}
          status={status}
          Placeholder={<TableComponentPlaceholder count={4} />}
        >
          <TableComponent
            showSorterTooltip={false}
            tableSize="large"
            style={{
              height: `calc((${deviceHeight}px - 22em) / 2)`,
              minHeight: `calc((${deviceHeight}px - 22em) / 2)`
            }}
            scroll={{
              x: 'calc((100vw - 213px) / 2)',
              y: isMobile
                ? deviceOS === 'IOS'
                  ? 'calc((100vh - 30.8em ) / 2)'
                  : 'calc((100vh - 28.8em) / 2)'
                : 'calc((100vh - 32.5em) / 2)'
            }}
            rowKey={record =>
              `${record?.ticketCategoryId}-${record?.workQueueId}-${record?.ticketSubCategoryId}`
            }
            className="configurations_settings_table"
            rowClassName={'hoverRow'}
            dataSource={data?.configurations}
            data={data?.configurations}
            columns={() => configurationsTableColumnsArray}
            setTotalRows={setTotalRowsConfigurations}
          />
        </ComponentWithStatus>
      </TableLayoutPage>
    </div>
  );
};
