import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import {
  setFormValidation,
  setMethodUpdate,
} from 'redux/slices/methodUpdateSlice';
import {
  notifyWithFailedValidation,
  notifyWithSuccessfulDataUpdated,
} from './notificationsUtils';

export const onFormFinishFailedValidation = (
  validationError: ValidateErrorEntity<any>,
  dispatch: Dispatch<AnyAction>
) => {
  const errorFields = validationError.errorFields.length
    ? validationError.errorFields.map((field) => field.errors)
    : [];
  dispatch(setFormValidation(false));
  notifyWithFailedValidation(errorFields);
};

export const onFormFinishWithPassedValidation = (
  onFinishActions: any,
  onFinishScope: string,
  data: any,
  actions: {
    dispatch?: Dispatch<AnyAction>;
    toggleModal?: () => void;
    triggerOtherActionsWithResult?: (res?: any) => void;
  },
  successMessage?: string | JSX.Element
) => {
  onFinishActions[onFinishScope](data).then((res) => {
    if (res.error) {
      return;
    }



    if (onFinishActions.hasToggleModal === undefined)
      onFinishActions.hasToggleModal = true;

    if (!actions) {
      return;
    }

    if (actions.dispatch && typeof actions.dispatch === 'function') {
      actions.dispatch(setMethodUpdate(true));
    }

    if (
      actions.triggerOtherActionsWithResult &&
      typeof actions.triggerOtherActionsWithResult === 'function'
    ) {
      actions.triggerOtherActionsWithResult(res);
    }

    // For some reasone - this action is called two times when a modal opens over another
    // modal - therefore a race condition is happening. Solution: give a flag to the modals
    // that open over another modal.
    if (
      actions.toggleModal &&
      onFinishActions.hasToggleModal &&
      typeof actions.toggleModal === 'function'
    ) {
      actions.toggleModal();
    }

    notifyWithSuccessfulDataUpdated('', successMessage);
  });
};
