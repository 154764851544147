import * as React from 'react';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import PlaceholderWrapper from '../UI/PlaceholderWrapper';
import { EventRecurrence, EventResponseDto, EventTimeFormat, EventTypes } from '../../../pages/Calendar/types';

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export const EmployeeFormPlaceholder: React.FC = () => {
  const { isMobile } = useMobileContext();

  return (
    <PlaceholderWrapper
      width="937px"
      height={isMobile ? '100%' : '690px'}
      borderRadius={isMobile ? '0' : '4px'}
    >
      <rect y="0%" rx="5" ry="5" width={`200px`} height="4%" />

      <rect x="0%" y="6%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="6%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect x="0%" y="13%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="13%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect x="0%" y="20%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="20%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect x="0%" y="27%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect y="34%" rx="5" ry="5" width={`200px`} height="4%" />

      <rect x="0%" y="40%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="40%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect x="0%" y="47%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="47%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect x="0%" y="54%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="54%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect x="0%" y="61%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="61%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect x="0%" y="68%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="68%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect x="0%" y="75%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect y="82%" rx="5" ry="5" width={`200px`} height="4%" />

      <rect x="0%" y="89%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="89%" rx="10px" ry="10px" width={`49%`} height="5%" />

      <rect x="0%" y="96%" rx="10px" ry="10px" width={`49%`} height="5%" />
      <rect x="51%" y="96%" rx="10px" ry="10px" width={`49%`} height="5%" />
    </PlaceholderWrapper>
  );
};

export const MobileEmployeeFormPlaceholder: React.FC = () => {
  const { deviceHeight } = useMobileContext();

  return (
    <PlaceholderWrapper
      width="440px"
      height={`${deviceHeight - 90 - 32 - 160}px`}
      style={{ maxWidth: '100%' }}
    >
      {new Array(11).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            y={`${(100 / 11) * i}%`}
            x="0"
            rx="5"
            ry="5"
            height={`${100 / 11 - 1}%`}
            width="100%"
          />
        );
      })}
    </PlaceholderWrapper>
  );
};

interface EventFormPlaceholderProps {
  heightProps?: string;
}

export const FormFieldPlaceholder: React.FC<EventFormPlaceholderProps> = ({
  heightProps
}) => {
  return (
    <PlaceholderWrapper width="100%" height={heightProps || '32px'}>
      <rect
        x="0"
        y="0"
        ry="10px"
        rx="10px"
        width="100%"
        height={heightProps || '32px'}
      />
    </PlaceholderWrapper>
  );
};

export const DocumentFormPlaceholder: React.FC = () => {
  return (
    <PlaceholderWrapper width="100%" height="100%">
      <rect x="0" y="0" ry="10px" rx="10px" width="98%" height="32px" />
      <rect x="0" y="40px" ry="10px" rx="10px" width="98%" height="32px" />
      <rect x="0" y="80px" ry="10px" rx="10px" width="98%" height="32px" />
      <rect x="0" y="120px" ry="10px" rx="10px" width="98%" height="32px" />
      <rect x="0" y="160px" ry="10px" rx="10px" width="98%" height="32px" />
      <rect x="0" y="200px" ry="10px" rx="10px" width="20%" height="32px" />
    </PlaceholderWrapper>
  );
};

export const CategoryFormPlaceholder: React.FC = () => {
  const gutter = 4;
  const count = 3;
  const height = 32;

  return (
    <PlaceholderWrapper width="436px" height="164px">
      {new Array(count).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x="0"
            y={`${(100 / gutter) * i}%`}
            ry="10"
            rx="10"
            width={getRandomArbitrary(40, 80)}
            height={height}
          />
        );
      })}
      {new Array(count).fill(' ').map((_, i) => {
        if (i === 2) {
          return (
            <rect
              key={i}
              x="100"
              y={`${(100 / gutter) * i}%`}
              ry="10"
              rx="10"
              width="320px"
              height={height * 2}
            />
          );
        }

        return (
          <rect
            key={i}
            x="100"
            y={`${(100 / gutter) * i}%`}
            ry="10"
            rx="10"
            width="320px"
            height={height}
          />
        );
      })}
    </PlaceholderWrapper>
  );
};

interface EventFormPlaceholderProps {
  heightProps?: string;
  widthProps?: string;
  hasDetails?: boolean;
  count?: number;
  gutter?: number;
}

export const GetEventPLaceHolder = (eventObject: EventResponseDto) => {
  const getCountAndGutter = (hasDetails: boolean, recurrence: boolean, timeFormat: boolean) => {
    if (recurrence) {
      return hasDetails
        ? timeFormat ? [8, 8] : [7, 7]
        : timeFormat ? [7, 7] : [6, 6];
    } else {
      return hasDetails
        ? timeFormat ? [7, 7] : [6, 6]
        : timeFormat ? [6, 6] : [5, 5];
    }
  };

  const [count, gutter] = getCountAndGutter(
    eventObject?.hasDetails,
    eventObject?.recurrence !== EventRecurrence.DoesNotRepeat,
    eventObject?.timeFormat === EventTimeFormat.TimeRange
  );

  return (
    <EditEventPLaceHolder
      count={eventObject?.eventType === EventTypes.Holiday ? count - 1 : count}
      gutter={eventObject?.eventType === EventTypes.Holiday ? gutter - 1 : gutter}
      widthProps={eventObject?.recurrence !== EventRecurrence.DoesNotRepeat ? '470px' : '412px'}
      hasDetails={eventObject?.hasDetails}
    />
  );
};

export const EditEventPLaceHolder: React.FC<EventFormPlaceholderProps> = (
  {
    heightProps,
    widthProps,
    hasDetails,
    count = 6,
    gutter = 6,
  }) => {
  const height = 32;
  const containerHeight = height * count + 16 * (gutter - 1);
  const adjustedHeight = hasDetails ? containerHeight + 32 : containerHeight;

  const renderRectangles = () => {
    return new Array(count).fill(' ').map((_, i) => (
      <rect
        key={i}
        x="0"
        y={`${(105 / gutter) * i}%`}
        ry="10"
        rx="10"
        width={getRandomArbitrary(40, 80)}
        height={height}
      />
    ));
  };

  const renderFullWidthRectangles = () => {
    return new Array(count).fill(' ').map((_, i) => (
      <rect
        key={i}
        x="100"
        y={`${(105 / gutter) * i}%`}
        ry="10"
        rx="10"
        width={`calc(${widthProps} - 100px)`}
        height={height}
      />
    ));
  };

  return (
    <PlaceholderWrapper width={widthProps || '470px'} height={`${adjustedHeight}px` || '334px'}>
      {renderRectangles()}
      {renderFullWidthRectangles()}
    </PlaceholderWrapper>
  );
};

interface TORFormPlaceholderProps {
  heightProps?: string;
}

export const TORFormPlaceholder: React.FC<TORFormPlaceholderProps> = ({
  heightProps
}) => {
  const gutter = 6;
  const count = 6;
  const height = 32;

  return (
    <PlaceholderWrapper width="422px" height={heightProps || '334px'}>
      {new Array(count + 1).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x="0"
            y={`${(100 / gutter) * i}%`}
            ry="10"
            rx="10"
            width={i === 5 ? '330px' : getRandomArbitrary(40, 80)}
            height={height}
          />
        );
      })}
      {new Array(count).fill(' ').map((_, i) => {
        if (i === 5)
          return (
            <circle key={i} cx="400" cy={`${(100 / gutter) * i + 4}%`} r="16" />
          );

        return (
          <rect
            key={i}
            x="100"
            y={`${(100 / gutter) * i}%`}
            ry="10"
            rx="10"
            width="320px"
            height={height}
          />
        );
      })}
    </PlaceholderWrapper>
  );
};

export const AssetFormPlaceholder: React.FC<any> = ({
  boxwidth = '875px',
  boxheight = '176px',
  count = 4,
  gutter = 4
}) => {
  const height = 32;
  const { isMobile } = useMobileContext();

  return (
    <PlaceholderWrapper width={boxwidth} height={boxheight}>
      {new Array(count).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x="0"
            y={`${(100 / gutter) * i}%`}
            ry="10"
            rx="10"
            width={getRandomArbitrary(40, 120)}
            height={height}
          />
        );
      })}
      {new Array(count).fill(' ').map((_, i) => {
        return (
          <rect
            key={i}
            x="140"
            y={`${(100 / gutter) * i}%`}
            ry="10"
            rx="10"
            width="320px"
            height={height}
          />
        );
      })}

      {!isMobile && (
        <>
          {new Array(count).fill(' ').map((_, i) => {
            return (
              <rect
                key={i}
                x="480"
                y={`${(100 / gutter) * i}%`}
                ry="10"
                rx="10"
                width={getRandomArbitrary(40, 120)}
                height={height}
              />
            );
          })}
          {new Array(count).fill(' ').map((_, i) => {
            return (
              <rect
                key={i}
                x="620"
                y={`${(100 / gutter) * i}%`}
                ry="10"
                rx="10"
                width="320px"
                height={height}
              />
            );
          })}
        </>
      )}
    </PlaceholderWrapper>
  );
};
