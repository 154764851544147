import { FC, useEffect, useMemo, useState } from 'react';
import ComponentWithStatus from 'components/ComponentWithStatus';
import EmployeeProfileCard from 'components/Employee/EmployeeProfileCard';
import { useDispatch, useSelector } from 'react-redux';
import useApiRequest from 'hooks/useApiRequest.hook';
import {
  IEmployee,
  IEmployeeStatistics
} from 'services/providers/EmployeeProvider/types';
import { EmployeeServices, TeamsService, TimeOffService } from 'services';
import { RootState } from 'redux/store';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { useCompanySettings } from 'settings';
import { PolicyConfig } from 'AppProvider/ConfigManager/config';
import { useAuth } from 'services/providers/AuthProvider';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole
} from 'utils/userManagement';
import {
  DashboardPagePlaceholder,
  MobilePagePlaceholder
} from 'components/Placeholders';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';

type DashboardProps = {};

const Dashboard: FC<DashboardProps> = () => {
  const { user } = useAuth();
  const [employeeId, setEmployeeId] = useState<number>(null);
  const { countryCode } = useCompanySettings();
  const { isMobile } = useMobileContext();
  const userRole = getCurrentUserRole(user);
  const isAdminUserRole = allowPermision(userRole, adminRoles);

  const dispatch = useDispatch();
  const [dataEmployeeTeams, setEmployeeTeams] = useState(null);
  const [dataEmployeeStatistics, setEmployeeStatistics] = useState<{
    data: IEmployeeStatistics;
  }>(null);
  const { data, status } = useApiRequest<IEmployee>(
    EmployeeServices.getEmployeeProfile,
    'false',
    {},
    {},
    null,
    [],
    false,
    true
  );

  const methodUpdateSelector = useSelector(
    (state: RootState) => state.methodUpdate
  );

  useEffect(() => {
    if (methodUpdateSelector.isMethodUpdated) {
      dispatch(setMethodUpdate(false));
    }

    if (status !== 'success' || !data) {
      return;
    }

    setEmployeeId(data.employeeId);
  }, [data, methodUpdateSelector.isMethodUpdated]);

  useEffect(() => {
    const getDataForWidgets = async () => {
      const teams = await TeamsService.getMyTeams();
      const statistics = await TimeOffService.getTimeOffStatistics();
      if (teams.error || statistics.error) {
        setEmployeeTeams(null);
        setEmployeeStatistics(null);
        return;
      }
      setEmployeeTeams(teams?.data);
      //set employee statistics and filter hidden policies
      setEmployeeStatistics({
        ...statistics,
        data: {
          ...statistics.data,
          statistics: statistics.data.statistics?.filter(
            ({ statisticType }) => {
              const policy = PolicyConfig[
                countryCode
              ]?.DefaultTimeOffRequests.find(
                policy => policy.request === statisticType
              );

              // If no matching policy is found, or if the policy is not hidden and not admin-only, include the statistic
              return (
                !policy ||
                (!policy.isHidden && !(policy.isAdminOnly && !isAdminUserRole))
              );
            }
          )
        }
      });
    };

    if (status === 'success') {
      getDataForWidgets();
    }
  }, [data]);

  return (
    <>
      <ComponentWithStatus
        status={status}
        Placeholder={
          isMobile ? <MobilePagePlaceholder /> : <DashboardPagePlaceholder />
        }
      >
        <EmployeeProfileCard
          scope="MyProfile"
          employeeId={employeeId}
          data={data}
          employeeTeamsData={dataEmployeeTeams && dataEmployeeTeams}
          employeeStatistics={dataEmployeeStatistics}
          page="dashboard"
        />
      </ComponentWithStatus>
    </>
  );
};

export default Dashboard;
