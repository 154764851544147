import { TicketPriority, TicketStatus } from 'pages/Ticketing/types';
import SelectorWithElement from '../../../../../../../components/SelectorWithElement';

import { TicketingService } from 'services';
import { FormattedMessage } from 'react-intl';
import { getTicketById } from 'redux/slices/ticketingSlice/actions';
import { LabelTag } from 'components/UIComponents/LabelTag';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import useIsUpdateOngoing from '../../../../../../../hooks/useIsUpdateOngoing';
import { useAuth } from 'services/providers/AuthProvider';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole
} from 'utils/userManagement';
import { getSelectOptions } from './utils';
import styles from '../../ticketsDetailsSection.module.less';
import SingleSelectDropdown from '../../../../../../../components/UIComponents/SingleSelectDropdown';
import { useThemeContext } from '../../../../../../../AppProvider/ConfigProviderSettings';
import { Typography } from 'antd';

export const StatusSelect = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { theme } = useThemeContext();
  const { selectedTicket: ticket } = useSelector(
    (state: RootState) => state.ticketing
  );
  const { user } = useAuth();
  const { observeUpdateEnd, isUpdateOnGoing } = useIsUpdateOngoing(response => {
    dispatch(getTicketById(response.data?.ticketId));
  });
  const onStatusChange = (status: TicketStatus) =>
    status !== ticket?.status &&
    observeUpdateEnd(
      async () =>
        await TicketingService.updateStatus({
          ticketId: ticket.ticketId,
          status: status
        })
    );

  const allowSelectionCheck = () => {
    const userRole = getCurrentUserRole(user);
    const isAdminUserRole = allowPermision(userRole, adminRoles);

    const isTicketClosed = ticket?.status === 'Closed';
    const isAssigneeOrAdmin =
      ticket?.assignee?.email.toLowerCase() === user?.email || isAdminUserRole;

    return !isTicketClosed && isAssigneeOrAdmin;
  };
  return (
    <>
      <div
        style={
          isUpdateOnGoing
            ? {
                opacity: 0.4,
                pointerEvents: 'none',
              }
            : {
              marginRight: '10px'
              }
        }
      >
        {allowSelectionCheck() ?
          <SingleSelectDropdown
          className={styles.selector}
          dropdownAlign={{ offset: [30, null] }}
          label={
            <LabelTag variant="primary">
              <FormattedMessage id={ticket?.status} />
            </LabelTag>
          }
          value={ticket?.status}
          theme={theme}
          options={getSelectOptions(ticket?.status).map(option => ({
            value: option.value,
            label: option.label.props.children
          }))}
          onChange={
            allowSelectionCheck()
              ? (selectedStatus: TicketStatus) => {
                onStatusChange(selectedStatus);
              }
              : undefined
          }
        /> :
          <LabelTag variant="primary">
            <FormattedMessage id={ticket?.status} />
          </LabelTag>
        }

      </div>
    </>
  );
};
