import { notification } from 'antd';
import './notificationsStyles.less';
import { FormattedMessage } from 'react-intl';
import {
  CustomIntlContextProvider,
  IntlContextProvider
} from 'AppProvider/ConfigProviderSettings';
import React from 'react';
import { DateToUTC, convertToLocalDate } from 'components/DateParser';

export const notifyWithError = (error: {
  message: string | JSX.Element;
  description: string | JSX.Element;
}) =>
  notification.error({
    message: (
      <CustomIntlContextProvider>
        <IntlContextProvider>{error.message}</IntlContextProvider>
      </CustomIntlContextProvider>
    ),
    description: (
      <CustomIntlContextProvider>
        <IntlContextProvider>{error.description}</IntlContextProvider>
      </CustomIntlContextProvider>
    )
  });

export const notifyWithFailedValidation = (errorFields: string[][]) =>
  notifyWithError({
    message: <FormattedMessage id="ValidationFailedErrorMessage" />,
    description: (
      <>
        {errorFields.map((errorMessage, index) => (
          <React.Fragment key={index}>
            <CustomIntlContextProvider>
              <IntlContextProvider>
                {errorMessage} <br />
              </IntlContextProvider>
            </CustomIntlContextProvider>
          </React.Fragment>
        ))}
      </>
    )
  });

export const notifyWithAxiosAPIError = (axiosError: any) => {
  const errorType = axiosError?.error?.message?.split(' ')?.join('');
  notifyWithError({
    message: errorType ? (
      <FormattedMessage id={errorType} />
    ) : (
      <FormattedMessage id="Error" />
    ),
    description:
      axiosError && typeof axiosError?.error === 'string' ? (
        <FormattedMessage id="UnexpectedErrorOccuredErrorMessage" />
      ) : typeof axiosError?.error === 'object' &&
        axiosError?.error?.errors &&
        Object.keys(axiosError?.error?.errors).length ? (
        axiosError?.error?.errors.map(error => {
          switch (error.field) {
            case 'PersonalLeaveNotOffsetAllowanceExceeded':
            case 'OffsetAmountExceedsPersonalLeaveAmount':
            case 'PersonalLeaveLessThanMinimumAmount':
            case 'OffsetInAdvanceAllowanceExceeded': {
              return (
                <>
                  <FormattedMessage
                    id={error?.field + 'ErrorDescription'}
                    values={{
                      ...JSON.parse(error?.message)
                    }}
                  />
                </>
              );
            }
            case 'FieldMustBeUnique': {
              const duplicatedField = error?.message
                ?.split(' ')
                .slice(-1)
                .toString();
              return (
                <>
                  <FormattedMessage id={error.field} /> -{' '}
                  <FormattedMessage
                    id={'DuplicatedfieldErrorDescription'}
                    values={{
                      field: <FormattedMessage id={duplicatedField} />
                    }}
                  />
                </>
              );
            }
            case 'OffsetNotInAllowedInterval': {
              const interval = JSON.parse(error?.message);
              const allowedIntervalStart = convertToLocalDate(
                interval.allowedIntervalStart
              ).format('HH:mm');
              const allowedIntervalEnd = convertToLocalDate(
                interval.allowedIntervalEnd
              ).format('HH:mm');

              return (
                <>
                  <FormattedMessage
                    id={error?.field + 'ErrorDescription'}
                    values={{
                      allowedIntervalStart: allowedIntervalStart,
                      allowedIntervalEnd: allowedIntervalEnd
                    }}
                  />
                </>
              );
            }
            case 'OffsetInAdvanceNotAllowed':
              return (
                <>
                  <FormattedMessage id={error.field + 'ErrorDescription'} />
                </>
              );

            default: {
              return (
                <>
                  <FormattedMessage id={error.field} /> -{' '}
                  <FormattedMessage id={error.field + 'ErrorDescription'} />
                </>
              );
            }
          }
        })
      ) : (
        <FormattedMessage id="UnexpectedErrorOccuredErrorMessage" />
      )
  });
};

export const notifyWithErrorOnMaxSizeUploadFile = (maxSizeInMb: number) =>
  notifyWithError({
    message: (
      <FormattedMessage
        id="MaximumFileSizeErrorMessage"
        values={{ maxSizeInMb: maxSizeInMb }}
      />
    ),
    description: null
  });

export const notifyWithSuccessfulDataUpdated = (
  successMessage?: string | JSX.Element,
  description?: string | JSX.Element
) => {
  notification.success({
    message: (
      <CustomIntlContextProvider>
        <IntlContextProvider>{successMessage}</IntlContextProvider>
      </CustomIntlContextProvider>
    ) ?? (
      <CustomIntlContextProvider>
        <IntlContextProvider>
          <FormattedMessage id="Success" />
        </IntlContextProvider>
      </CustomIntlContextProvider>
    ),
    description: (
      <CustomIntlContextProvider>
        <IntlContextProvider>{description}</IntlContextProvider>
      </CustomIntlContextProvider>
    ) ?? (
      <CustomIntlContextProvider>
        <IntlContextProvider>
          <FormattedMessage id="DataHasBeenUpdatedSuccessMessage" />
        </IntlContextProvider>
      </CustomIntlContextProvider>
    )
  });
};
