import { useAuth } from 'services/providers/AuthProvider';
import { LabelTag } from 'components/UIComponents/LabelTag';
import { Employee, TicketHistoryDto } from 'pages/Ticketing/types';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import MarkAsResolvedBtn from './MarkAsResolvedBtn';
import { SafeFormattedMessage } from 'components/UIComponents/SafeFormattedMessage';

export const HistoryUpdateType: React.FC<{
  update: TicketHistoryDto;
  requester: Employee;
  dueDate: any;
  isLast: boolean;
}> = ({ update, requester, dueDate, isLast }) => {
  const { user } = useAuth();
  const isUserRequester =
    requester.email.toLowerCase() === user.email.toLowerCase();
  const displayMarkAsResolvedBtnCheck = isLast && isUserRequester;

  //priority
  if (update?.updateType === 'Priority') {
    return (
      <FormattedMessage
        id="TicketHistory_priorityUpdate"
        values={{
          previousPriority: (
            <LabelTag>
              <SafeFormattedMessage id={update?.previousPriority} />
            </LabelTag>
          ),
          updatedPriority: (
            <LabelTag>
              <SafeFormattedMessage id={update?.updatedPriority} />
            </LabelTag>
          ),
          employeeName:
            update?.updatedBy?.lastName + ' ' + update?.updatedBy?.firstName,
        }}
      />
    );
  }
  //status
  if (update?.updateType === 'Status') {
    if (update?.updatedStatus === 'Resolved') {
      if (!update?.updatedBy) {
        return (
          <FormattedMessage
            id="TicketHistory_systemMarkAsSoved"
            values={{
              updatedStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.updatedStatus} />
                </LabelTag>
              ),
              previousStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.previousStatus} />
                </LabelTag>
              ),
            }}
          />
        );
      } else
        return displayMarkAsResolvedBtnCheck ? (
          <>
            <FormattedMessage
              id="TicketHistory_userMarkAsSoved"
              values={{
                updatedStatus: (
                  <LabelTag>
                    <SafeFormattedMessage id={update?.updatedStatus} />
                  </LabelTag>
                ),
                employeeName:
                  update?.updatedBy?.lastName +
                  ' ' +
                  update?.updatedBy?.firstName,
                daysLeft: dayjs(dayjs(dueDate)).diff(dayjs(), 'days'),
              }}
            />
            <MarkAsResolvedBtn />
          </>
        ) : (
          <FormattedMessage
            id="TicketHistory_statusUpdate"
            values={{
              updatedStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.updatedStatus} />
                </LabelTag>
              ),
              employeeName:
                update?.updatedBy?.lastName +
                ' ' +
                update?.updatedBy?.firstName,
            }}
          />
        );
    } else if (update?.updatedBy?.email?.toLowerCase() !== user.email) {
      if (
        update?.updatedStatus === 'Open' &&
        update?.previousStatus === 'Resolved'
      ) {
        return (
          <FormattedMessage
            id="TicketHistory_userFeedbackNegative"
            values={{
              updatedStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.updatedStatus} />
                </LabelTag>
              ),
              previousStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.previousStatus} />
                </LabelTag>
              ),
              employeeName: requester?.lastName + ' ' + requester?.firstName,
            }}
          />
        );
      }

      if (
        update?.updatedStatus === 'Open' &&
        update?.previousStatus === 'New'
      ) {
        return (
          <FormattedMessage
            id="TicketHistory_statusUpdate"
            values={{
              updatedStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.updatedStatus} />
                </LabelTag>
              ),
              employeeName:
                update?.updatedBy?.lastName +
                ' ' +
                update?.updatedBy?.firstName,
            }}
          />
        );
      }

      if (update?.updatedStatus === 'Closed') {
        if (!update?.updatedBy) {
          return (
            <FormattedMessage
              id="TicketHistory_systemMarkAsSoved"
              values={{
                updatedStatus: (
                  <LabelTag>
                    <SafeFormattedMessage id={update?.updatedStatus} />
                  </LabelTag>
                ),
                previousStatus: (
                  <LabelTag>
                    <SafeFormattedMessage id={update?.previousStatus} />
                  </LabelTag>
                ),
              }}
            />
          );
        }
        return (
          <FormattedMessage
            id="TicketHistory_userFeedbackPositive"
            values={{
              updatedStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.updatedStatus} />
                </LabelTag>
              ),
              previousStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.previousStatus} />
                </LabelTag>
              ),
              employeeName: requester?.lastName + ' ' + requester?.firstName,
            }}
          />
        );
      }
    } else {
      if (
        update?.updatedStatus === 'Open' &&
        update?.previousStatus === 'Resolved'
      ) {
        return (
          <FormattedMessage
            id="TicketHistory_userFeedbackNegative"
            values={{
              updatedStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.updatedStatus} />
                </LabelTag>
              ),
              previousStatus: (
                <LabelTag>
                  <SafeFormattedMessage id={update?.previousStatus} />
                </LabelTag>
              ),
              employeeName: requester?.lastName + ' ' + requester?.firstName,
            }}
          />
        );
      }

      if (update?.updatedStatus === 'Closed') {
        if (!update?.updatedBy) {
          return (
            <FormattedMessage
              id="TicketHistory_systemMarkAsSoved"
              values={{
                updatedStatus: (
                  <LabelTag>
                    <SafeFormattedMessage id={update?.updatedStatus} />
                  </LabelTag>
                ),
                previousStatus: (
                  <LabelTag>
                    <SafeFormattedMessage id={update?.previousStatus} />
                  </LabelTag>
                ),
              }}
            />
          );
        }

        if (update?.updatedBy) {
          return (
            <FormattedMessage
              id="TicketHistory_userFeedbackPositive"
              values={{
                updatedStatus: (
                  <LabelTag>
                    <SafeFormattedMessage id={update?.updatedStatus} />
                  </LabelTag>
                ),
                previousStatus: (
                  <LabelTag>
                    <SafeFormattedMessage id={update?.previousStatus} />
                  </LabelTag>
                ),
                employeeName: requester?.lastName + ' ' + requester?.firstName,
              }}
            />
          );
        }
      }

      return (
        <FormattedMessage
          id="TicketHistory_statusUpdate"
          values={{
            updatedStatus: (
              <LabelTag>
                <SafeFormattedMessage id={update?.updatedStatus} />
              </LabelTag>
            ),
            employeeName:
              update?.updatedBy?.lastName + ' ' + update?.updatedBy?.firstName,
          }}
        />
      );
    }
  }
  //content
  if (update?.updateType === 'Content') {
    return (
      <FormattedMessage
        id="TicketHistory_detailsUpdate"
        values={{
          updatedDetails: <LabelTag>{update?.updatedDetails}</LabelTag>,
        }}
      />
    );
  }

  // Assignee
  if (update?.updateType === 'Assignee' && !update?.previousAssignee) {
    return (
      <FormattedMessage
        id="TicketHistory_unassigned"
        values={{
          updatedAssignee:
            update?.updatedAssignee?.lastName +
            ' ' +
            update?.updatedAssignee?.firstName,
          initiatorName:
            update?.updatedBy?.lastName + ' ' + update?.updatedBy?.firstName,
        }}
      />
    );
  } else
    return (
      <FormattedMessage
        id="TicketHistory_assigneeUpdate"
        values={{
          updatedAssignee:
            update?.updatedAssignee?.lastName +
            ' ' +
            update?.updatedAssignee?.firstName,
          previousAssignee:
            update?.previousAssignee?.lastName +
            ' ' +
            update?.previousAssignee?.firstName,
          initiatorName:
            update?.updatedBy?.lastName + ' ' + update?.updatedBy?.firstName,
        }}
      />
    );
};
