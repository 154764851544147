import { PlusCircleOutlined } from '@ant-design/icons';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import ModalComponent from 'components/Modal';
import { useModalContext } from 'components/Modal/ModalProvider';
import { TableComponentPlaceholder } from 'components/Placeholders';
import { SettingsCategoryForm } from 'components/SetitingsComponents/Category/SettingsCategoryForm';
import TableComponent from 'components/Table';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCategoryTableColumns } from './useCategoryTableColumns';
import useApiRequestHook from '../../../hooks/useApiRequest.hook';
import {
  TicketingSettingsService, TicketingWorkQueueService
} from '../../../services';
import { ITicketCategoryWithDetails } from '../../../services/providers/TicketingSettingsProvider/types';
import { useWorkQueueTableColumns } from '../WorkQueueTicketing/useWorkQueueTableColumns';
import { WorkQueueForm } from '../../../components/SetitingsComponents/WorkQueue/WorkQueueForm';
import '../settings_module.less';


let deleteModalWidth: string;
let editModalWidth: string;
let addMoldalWidth: string;

const categoryWidth = () => {
  if (window.innerWidth <= 768) {
    editModalWidth = '100%';
    addMoldalWidth = '100%';
    deleteModalWidth = '100%';
  } else {
    editModalWidth = '30%';
    addMoldalWidth = '30%';
    deleteModalWidth = '20%';
  }
};

export const SettingsPage = () => {
  const intl = useIntl();
  const {
    toggleModal,
    setIsModalOpen,
  } = useModalContext();
  const ButtonComponent = BaseButton(ButtonSimple);

  const { deviceHeight, isMobile, deviceOS } = useMobileContext();

  const [modalWidth, setModalWidth] = useState<string>();
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (isMobile) {
      setModalWidth('100%');
    } else {
      setModalWidth('45%');
    }
    categoryWidth();
  }, [isMobile]);

  const { data, status: categoryStatus } = useApiRequestHook<ITicketCategoryWithDetails[]>(
    TicketingSettingsService.getAllCategoriesWithDetails,
    'true',
    [],
    {}
  );

  const [modalProps, setModalProps] = useState({
    scope: 'add',
    key: null,
    modalTitle: null,
    category: null,
    submitButtonText: <FormattedMessage id="submit" />,
    cancelButtonText: <FormattedMessage id="cancel" />,
    displayFooterSubmitButton: true,
    displayFooterCloseButton: true,
    width: modalWidth,
    children: null
  });

  const categoryTableColumnsArray = useCategoryTableColumns(
    setIsModalOpen,
    setModalProps,
    editModalWidth,
    deleteModalWidth
  );

  const workQueueTableColumns = useWorkQueueTableColumns(
    setIsModalOpen,
    setModalProps,
    editModalWidth,
    deleteModalWidth
  );

  const { data: workQueueData, status } = useApiRequestHook<any[]>(
    TicketingWorkQueueService.getAllWorkQueues,
    'true',
    [],
    {}
  );

  return (
    <div className="configurationContainer">
      <ModalComponent
        key={modalProps.key}
        title={modalProps.modalTitle}
        submitBtnText={modalProps.submitButtonText}
        displayFooterSubmitButton={modalProps?.displayFooterSubmitButton}
        cancelBtnText={modalProps.cancelButtonText}
        displayFooterCloseButton={modalProps?.displayFooterCloseButton}
        formKeyId={modalProps.key}
        className='modal_without_effects_select'
      >
        {modalProps.children}
      </ModalComponent>
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        buttons={[
          {
            icon: <PlusCircleOutlined />,
            text: (
              <span>
                {intl.formatMessage({ id: 'Settings_WorkQueues_Add_Category' }).toLowerCase()}
              </span>
            ),
            action: () => {
              setModalProps(state => ({
                ...state,
                scope: 'add',
                key: 'ticketingCategoryForm',
                categoryId: null,
                submitButtonText: <FormattedMessage id="add" />,
                cancelButtonText: <FormattedMessage id="close" />,
                displayFooterSubmitButton: true,
                displayFooterCloseButton: true,
                modalTitle: <FormattedMessage id="Settings_WorkQueues_Add_Category" />,
                width: addMoldalWidth,
                children: (
                  <SettingsCategoryForm
                    scope={'add'}
                    category={null}
                  />
                ),
              }));
              toggleModal();
            },
            show: true
          },
          {
            icon: <PlusCircleOutlined />,
            text: <span>{intl.formatMessage({ id: 'Settings_WorkQueues_Add_Work_Queue' }).toLowerCase()}</span>,
            action: () => {
              setModalProps(state => ({
                ...state,
                scope: 'add',
                key: 'workQueueForm',
                categoryId: null,
                submitButtonText: <FormattedMessage id="add" />,
                cancelButtonText: <FormattedMessage id="close" />,
                displayFooterSubmitButton: true,
                displayFooterCloseButton: true,
                modalTitle: <FormattedMessage id="Settings_WorkQueues_Add_Work_Queue" />,
                width: addMoldalWidth,
                children: (
                  <WorkQueueForm
                    scope={'add'}
                    workQueueId={null}
                    workQueueName={null}
                  />
                ),
              }));
              toggleModal();
            },
            show: true,
          }
        ]}
        children={<>&nbsp;</>}
      />
      <br />
      <TableLayoutPage
        title={<FormattedMessage id="Settings_Categories_subcategories_list" />}
        renderCustomButton
        displayToggle
        style={{
          height: `calc((${deviceHeight}px - 202px ) / 2)`,
          overflow: 'hidden'
        }}
      >
        <ComponentWithStatus
          ignoreAnimation={true}
          status={categoryStatus}
          Placeholder={<TableComponentPlaceholder count={5} />}
        >
          <TableComponent
            showSorterTooltip={false}

            hookUseStateActions={{
              setModalProps
            }}
            tableSize="large"
            style={{
              height: `calc((${deviceHeight}px - 22em) / 2)`,
              minHeight: `calc((${deviceHeight}px - 22em) / 2)`
            }}
            scroll={{
              x: 'calc((100vw - 213px) / 2)',
              y: isMobile
                ? deviceOS === 'IOS'
                  ? 'calc((100vh - 30.8em ) / 2)'
                  : 'calc((100vh - 28.8em) / 2)'
                : 'calc((100vh - 32.5em) / 2)'
            }}
            rowKey={'categoryId'}
            className="category_settings_table"
            rowClassName={'hoverRow'}
            dataSource={data}
            data={data}
            columns={() => categoryTableColumnsArray}
          />
        </ComponentWithStatus>
      </TableLayoutPage>
      <br />
      <TableLayoutPage
        title={
          <FormattedMessage
            id="Settings_WorkQueues_Work_Queue_List"
            values={{ totalRows }}
          />
        }
        renderCustomButton
        displayToggle
        style={{
          height: `calc((${deviceHeight}px - 202px) / 2)`,
          overflow: 'hidden'
        }}
      >
        <ComponentWithStatus
          ignoreAnimation={true}
          status={status}
          Placeholder={<TableComponentPlaceholder count={4} />}
        >
          <TableComponent
            showSorterTooltip={false}
            tableSize="large"
            style={{
              height: `calc((${deviceHeight}px - 22em ) / 2)`,
              minHeight: `calc((${deviceHeight}px - 22em) / 2 )`
            }}
            scroll={{
              x: 'calc((100vw - 213px) / 2)',
              y: isMobile
                ? deviceOS === 'IOS'
                  ? 'calc((100vh - 30.8em ) / 2)'
                  : 'calc((100vh - 28.8em) /2)'
                : 'calc((100vh - 32.5em) /2)'
            }}
            rowKey={'workQueueId'}
            className="work_queue_settings_table"
            rowClassName={'hoverRow'}
            dataSource={workQueueData}
            data={workQueueData}
            columns={() => workQueueTableColumns}
            setTotalRows={setTotalRows}
          />
        </ComponentWithStatus>
      </TableLayoutPage>
    </div>
  );
};
