import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import ModalComponent from 'components/Modal';
import SplideCustomCarousel from 'components/SplideCarousel';
import { useTicketingModal } from 'components/Ticketing/TicketingModalContext';
import IncidentLetterIcon from 'Icons/Ticketing/incidentLetter';
import ServicePeopleIcon from 'Icons/Ticketing/ServicePeople';
import { FormattedMessage, useIntl } from 'react-intl';

import ComponentWithStatus from 'components/ComponentWithStatus';
import { TicketingPlaceholderMobile } from 'components/Placeholders/Mobile';
import { TicketingPlaceholder } from 'components/Placeholders/Widgets';
import useApiRequest from 'hooks/useApiRequest.hook';
import { TicketingService } from 'services';
import CardItemTicketing from './components/CardItemTicketing';
import './TicketingWidgetMigrated.less';

const ticketingItems = [
  {
    icon: <ServicePeopleIcon />,
    title: <FormattedMessage id="Ticketing_Service" />,
    hoverButtonTitle: <FormattedMessage id="ticketing_btn_Service" />,
    scope: 'Request'
  },
  {
    icon: <IncidentLetterIcon />,
    title: <FormattedMessage id="Ticketing_Incident" />,
    hoverButtonTitle: <FormattedMessage id="ticketing_btn_Incident" />,
    scope: 'Incident'
  }
];

const TicketingWidgetMigrated = () => {
  const { Text } = Typography;
  const { theme } = useThemeContext();
  const [hoveredItem, setHoveredItem] = useState<number>(-1);
  const { isMobile } = useMobileContext();
  const { ticketingModalProps, openFormTicketingModal } = useTicketingModal();
  const intl = useIntl();
  const [locale, setLocale] = useState(intl.locale);
  const [carouselKey, setCarouselKey] = useState(Date.now());

  useEffect(() => {
    setCarouselKey(Date.now());
  }, [intl.locale]);

  const { data: ticketingPreview, status } = useApiRequest<any>(
    TicketingService.getMyLastTickets,
    {},
    []
  );

  return (
    <>
      <ModalComponent {...ticketingModalProps} />
      <div className="ticketing__card__item">
        <div
          className={`ticketing__card ${theme}`}
          style={{ overflow: 'hidden' }}
        >
          <div className="ticketing__card__header">
            {status === 'success' && (
              <Text className="title_ticketing_widget">
                <FormattedMessage id="Ticketing" />
              </Text>
            )}
            {status === 'pending' && (
              <div
                style={{
                  minHeight: '20px',
                  minWidth: '120px',
                  borderRadius: '4px',
                  background: theme === 'light' ? '#E9E4F6' : '#656374'
                }}
              >
                &nbsp;
              </div>
            )}
          </div>
          <ComponentWithStatus
            status={status}
            Placeholder={
              isMobile ? (
                <TicketingPlaceholderMobile />
              ) : (
                <TicketingPlaceholder />
              )
            }
          >
            <SplideCustomCarousel
              key={carouselKey}
              style={{
                height: '100%',
                width: '100%'
              }}
              options={{
                easing: 'ease-in-out',
                speed: 800,
                perMove: 1,
                perPage: 1,
                drag: false,
                type: 'loop'
                // rewind: true,
              }}
              type={4}
            >
              {ticketingItems.map((item, index) => (
                <CardItemTicketing
                  key={index}
                  indexkey={index}
                  item={item}
                  ticketingPreview={ticketingPreview ?? []}
                  onHover={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(-1)}
                  isHovered={isMobile ? true : hoveredItem === index}
                />
              ))}
            </SplideCustomCarousel>
          </ComponentWithStatus>
        </div>
      </div>
    </>
  );
};

export default TicketingWidgetMigrated;
