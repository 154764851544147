import { createContext, useContext, useState } from 'react';
import { parseJwt } from 'pages/Redirect/AuthRedirect/utils';
import { IEmployee } from 'services/providers/EmployeeProvider/types';
import { generateHash } from 'utils/stringOperationsManager';
import { AuthService } from 'services';
import { TokenInfo, AuthTenant } from 'services/providers/AuthProvider/types';
import { getMillisFromTicks } from 'utils/displayDate';
import { useLocation } from 'react-router-dom';

export const getAuthToken = (): string | null => {
  const tokenInfoObj = localStorage.getItem('tokenInfo');
  if (tokenInfoObj) {
    const tokenInfo = JSON.parse(tokenInfoObj) as TokenInfo;
    if (tokenInfo) {
      return tokenInfo.accessToken;
    }
  } else return null;
};

export const checkAuthTokenSilently = async () => {
  const tokenInfoObj = localStorage.getItem('tokenInfo');
  const dateNow = Date.now();
  if (tokenInfoObj) {
    const tokenInfo = JSON.parse(tokenInfoObj) as TokenInfo;
    const isTokenExpired = getMillisFromTicks(tokenInfo.expiration) < dateNow;
    const isRefreshTokenExpired =
      getMillisFromTicks(tokenInfo.refreshExpiration) < dateNow;
    if (isTokenExpired && !isRefreshTokenExpired) {
      const refreshTokenResponse = await AuthService.refreshToken({
        ...tokenInfo
      }).catch(error => {
        console.error('Error refreshing token', error);
        localStorage.removeItem('tokenInfo');
      });
      if (refreshTokenResponse && refreshTokenResponse.data) {
        localStorage.setItem(
          'tokenInfo',
          JSON.stringify(refreshTokenResponse.data)
        );
      }

      window.location.reload();
    } else if (isTokenExpired && isRefreshTokenExpired) {
      localStorage.removeItem('tokenInfo');
      !window.location.href.includes('/login') &&
      !window.location.href.includes('/tenant') &&
      sessionStorage.setItem('LastVisitedPage', window.location.href);
      window.location.replace(`${process.env.REACT_APP_DOMAIN}/login`);
    }
  }
};

document.addEventListener('visibilitychange', handleVisibilityChange);

async function handleVisibilityChange() {
  if (!document.hidden) {
    await checkAuthTokenSilently();
  }
}

const useAuthProviderContext = () => {
  const [user, setUser] = useState<IEmployee>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let isAuthenticated: boolean = !!user;

  !isAuthenticated &&
    !window.location.href.includes('/login') &&
    !window.location.href.includes('/tenant') &&
    sessionStorage.setItem('LastVisitedPage', window.location.href);

  const logout = (options?: { returnTo: string }) => {
    setUser(null);
    localStorage.removeItem('tokenInfo');
    if (options && options.returnTo) {
      window.location.replace(options.returnTo);
    }
  };

  const initiateAuthenticationWith = (authProvider: string) => {
    const localUrl = `${process.env.REACT_APP_DOMAIN}/loginCallBack`;
    const authState = Math.random().toString(36).substring(2, 7);
    localStorage.setItem('aszssty', authState);
    switch (authProvider) {
      case 'google': {
        const googleClientId = process.env.REACT_APP_AUTH_GOOGLE_CLIENT_ID;
        const googleUrl = `https://accounts.google.com/o/oauth2/auth?response_type=id_token&scope=openid%20profile%20email&state=${authState}&nonce=${generateHash(
          googleClientId
        )}&client_id=${googleClientId}&redirect_uri=${localUrl}`;
        window.location.replace(googleUrl);
        break;
      }
      case 'ms': {
        const microfostClientId = process.env.REACT_APP_AUTH_MS_CLIENT_ID;
        const microsoftUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=id_token&scope=openid%20email&state=${authState}&nonce=${generateHash(
          microfostClientId
        )}&client_id=${microfostClientId}&redirect_uri=${localUrl}`;
        window.location.replace(microsoftUrl);
        break;
      }
      default:
        break;
    }
  };

  const authenticateUser = async (
    idToken?: string,
    provider?: 'ms' | 'google',
    tenantId?: number
  ): Promise<any> => {
    setIsLoading(true);
    let isUserAuthenticated = false;
    let tenants: AuthTenant[] = [];
    if (!idToken || !provider) {
      const params = new URLSearchParams(window.location.hash);
      const localAuthState = localStorage.getItem('aszssty');
      const stateFromProvider = params.get('state') || params.get('#state');
      if (localAuthState?.localeCompare(stateFromProvider) !== 0) {
        throw new Error(
          'There is a mismatch between session iniated with a provider and the current request.'
        );
      }
      // localStorage.removeItem('aszssty');
      idToken = params.get('id_token') || params.get('#id_token');
      provider = params.get('session_state') ? 'ms' : 'google';
    }

    if (idToken && provider) {
      try {
        var response = await AuthService.authenticate({
          providerToken: idToken,
          provider,
          tenantId
        });
        if (response) localStorage.removeItem('aszssty');
        if (response?.data?.accessToken) {
          setUser({ email: parseJwt(idToken).email } as IEmployee);
          localStorage.setItem('tokenInfo', JSON.stringify(response.data));
          isUserAuthenticated = true;
        } else if (response?.data?.tenants) {
          tenants = response?.data?.tenants;
        } else {
          throw new Error('Authentication on platform failed');
        }
      } catch (error) {
        throw new Error('Authentication failed');
      } finally {
        setIsLoading(false);
      }
      return { isUserAuthenticated, tenants, idToken, provider };
    }

    throw new Error(
      'There is an authentiation exception comming from auth provider'
    );
  };

  const getAccessTokenSilently = (): string => getAuthToken();

  return {
    user,
    isAuthenticated,
    isLoading,
    setUser,
    initiateAuthenticationWith,
    authenticateUser,
    getAccessTokenSilently,
    logout
  };
};

const AuthProviderContext = createContext(
  {} as ReturnType<typeof useAuthProviderContext>
);

export const useAuth = () => useContext(AuthProviderContext);

export const AuthContextProvider = ({ children }) => (
  <AuthProviderContext.Provider value={useAuthProviderContext()}>
    {children}
  </AuthProviderContext.Provider>
);
