import { TicketPriority, TicketStatus } from 'pages/Ticketing/types';
import SelectorWithElement from '../../../../../../../components/SelectorWithElement';
import styles from '../../ticketsDetailsSection.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { FormattedMessage } from 'react-intl';
import {
  getTicketById,
  updateTicketPriority
} from 'redux/slices/ticketingSlice/actions';
import { TicketPriorityElement } from 'pages/Ticketing/utils';
import useIsUpdateOngoing from '../../../../../../../hooks/useIsUpdateOngoing';
import { useAuth } from 'services/providers/AuthProvider';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole
} from 'utils/userManagement';
import { Typography } from 'antd';
import SingleSelectDropdown from '../../../../../../../components/UIComponents/SingleSelectDropdown';
import { EventsViewType } from '../../../../../../Calendar/types';
import { useState } from 'react';
import { useThemeContext } from '../../../../../../../AppProvider/ConfigProviderSettings';
export const TicketPrioritySelectOptions = [
  {
    value: TicketPriority.Low,
    label: (
      <Typography>
        <FormattedMessage id="Low" />
      </Typography>
    )
  },
  {
    value: TicketPriority.Medium,
    label: (
      <Typography>
        <FormattedMessage id="Medium" />
      </Typography>
    )
  },
  {
    value: TicketPriority.High,
    label: (
      <Typography>
        <FormattedMessage id="High" />
      </Typography>
    )
  },
  {
    value: TicketPriority.Critical,
    label: (
      <Typography>
        <FormattedMessage id="Critical" />
      </Typography>
    )
  }
];

const PrioritySelect = () => {
  const { selectedTicket: ticket } = useSelector(
    (state: RootState) => state.ticketing
  );
  const { theme } = useThemeContext();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAuth();
  const { observeUpdateEnd, isUpdateOnGoing } = useIsUpdateOngoing(
    response => {
      dispatch(getTicketById(ticket?.ticketId));
    },
    error => console.log('failed to change priority', error)
  );

  const onPriorityChange = (priority: TicketPriority) =>
    priority !== ticket?.priority &&
    observeUpdateEnd(
      async () =>
        await dispatch(
          updateTicketPriority({ ticketId: ticket?.ticketId, priority })
        )
    );
  const allowSelectionCheck = () => {
    const userRole = getCurrentUserRole(user);
    const isAdminUserRole = allowPermision(userRole, adminRoles);

    const isTicketClosed = ticket?.status === 'Closed';
    const isAssigneeOrAdmin =
      ticket?.assignee?.email.toLowerCase() === user?.email || isAdminUserRole;

    return !isTicketClosed && isAssigneeOrAdmin;
  };
  return (
    <div
      style={
        isUpdateOnGoing
          ? {
              opacity: 0.4,
              pointerEvents: 'none'
            }
          : {
            marginRight: '10px'
            }
      }
    >
      {allowSelectionCheck()?
        <SingleSelectDropdown
          className={styles.selector}
          dropdownAlign={{ offset: [30, null] }}
          label={<TicketPriorityElement priority={ticket?.priority} />}
          value={ticket?.priority}
          theme={theme}
          options={TicketPrioritySelectOptions.map(option => ({
            value: option.value,
            label: option.label.props.children
          }))}
          onChange={
            allowSelectionCheck()
              ? (selectedPriority: TicketPriority) => {

                onPriorityChange(selectedPriority);
              }
              : undefined
          }
        /> :
        <TicketPriorityElement priority={ticket?.priority} />
      }
    </div>
  );
};
export default PrioritySelect;
