import { ReportsFiltersProvider } from 'components/ReportsDetails/ReportsFilterContext';
import LoginCallBack from 'pages/Authentication/LoginCallBack';
import LoginPage from 'pages/Authentication/LoginPage';
import TenantChooser from 'pages/Authentication/TenantChooserPage';
import { CalendarPage } from 'pages/Calendar';
import Dashboard from 'pages/Dashboard';
import EmployeesPage from 'pages/Employee/EmployeesPage';
import InventoryGateway from 'pages/Inventory';
import ViewManageCategories from 'pages/Inventory/Categories';
import ViewHardware from 'pages/Inventory/Hardware';
import ViewSoftware from 'pages/Inventory/Software';
import Profile from 'pages/Profile';
import AuthRedirect from 'pages/Redirect/AuthRedirect';
import LogoutRedirect from 'pages/Redirect/LogoutRedirect';
import ReportsPage from 'pages/Reports';
import { ReportsDetailsPage } from 'pages/Reports/ReportsDetailsPage';
import { SettingsPage } from 'pages/Settings/CategoryTicketing';
import { ViewCategoryById } from 'pages/Settings/CategoryTicketing/ViewCategory';
import TeamAddPage from 'pages/Team/TeamAddPage';
import TeamsPage from 'pages/Team/TeamsPage';
import ViewTeam from 'pages/Team/ViewTeamPage';
import TicketingPage from 'pages/Ticketing';
import TicketingDetailedView from 'pages/Ticketing/TicketingDetailedView';
import TicketingListView from 'pages/Ticketing/TicketingListView';
import ColleaguesTimeOffPage from 'pages/TimeManagement/ColleaguesTimeOffRequests';
import PersonalTimeOffPage from 'pages/TimeManagement/PersonalTimeOffRequests';
import { ViewTimeSheet } from 'pages/TimeManagement/TimeSheet';
import { TimeSheetModule } from 'pages/TimeManagement/TimeSheet/TimeSheetMigrated';
import { VacationModule } from 'pages/TimeManagement/Vacation';
import { Outlet } from 'react-router-dom';
import { IRoutesConfig } from 'routing/routesService';
import ErrorBoundary from '../../utils/ErrorBoundary';
import { ViewWorkQueue } from 'pages/Settings/WorkQueueTicketing/ViewWorkQueue';
import AttendanceReport from 'pages/TimeManagement/AttendanceReport';

export const roles = {
  ADMIN: 'Admin',
  OPERATION: 'OPERATION',
  REGULAR: 'REGULAR'
};

export const routes: IRoutesConfig = {
  publicRedirectRoute: '/login',
  privateRedirectRoute: '/',
  defaultFallback: <p>loading...</p>,
  InvalidUserRoleFallback: ({ currentUserRole, routeRequiredRoles }) => (
    <>
      <p>User role is not allowed for this page </p>
    </>
  ),
  public: [
    {
      path: '/login',
      component: (
        <ErrorBoundary>
          <LoginPage />
        </ErrorBoundary>
      )
    },
    {
      path: '/tenant',
      component: (
        <ErrorBoundary>
          <TenantChooser />
        </ErrorBoundary>
      )
    },
    {
      path: '/loginCallBack',
      component: (
        <ErrorBoundary>
          <LoginCallBack />
        </ErrorBoundary>
      )
    }
  ],
  private: [
    {
      path: '/',
      component: <Dashboard />
    },
    {
      path: '/dashboard',
      component: <Dashboard />
    },
    {
      path: '/allUsersList',
      component: <Outlet />,
      children: [
        {
          index: true,
          component: <EmployeesPage />
        },
        {
          path: 'viewProfile',
          component: <Profile />
        }
      ]
    },
    {
      path: '/teams',
      component: <Outlet />,
      children: [
        {
          index: true,
          component: <TeamsPage />
        },

        {
          path: 'viewTeam',
          component: <ViewTeam />
        },
        {
          path: 'addTeam',
          component: <TeamAddPage />
        }
      ]
    },
    {
      path: '/timeManagement',
      component: <Outlet />,
      children: [
        {
          index: true,
          path: 'personalRequests',
          component: <PersonalTimeOffPage />
        },
        {
          path: 'colleaguesRequests',
          component: <ColleaguesTimeOffPage />
        },
        {
          path: 'timesheetOld',
          component: <ViewTimeSheet />,
          roles: ['Admin', 'HR', 'Accountant']
        },
        {
          path: 'timesheet',
          component: <TimeSheetModule />,
          roles: ['Admin', 'HR', 'Accountant']
        },
        {
          path: 'vacation',
          component: <VacationModule />
        },
        {
          path: 'attendanceReport',
          component: <AttendanceReport />
        }
      ]
    },
    {
      path: '/inventory',
      component: <Outlet />,
      children: [
        {
          path: 'gateway',
          component: <InventoryGateway />
        },
        {
          path: 'hardware',
          component: <ViewHardware />
        },
        {
          path: 'software',
          component: <ViewSoftware />
        },
        {
          path: 'categories',
          component: <ViewManageCategories />
        }
      ]
    },
    {
      path: '/calendar',
      component: <CalendarPage />
    },
    {
      path: '/ticketing',
      component: <TicketingPage />,
      children: [
        {
          path: '',
          component: <TicketingListView />
        },
        {
          path: 'viewTicket',
          component: <TicketingDetailedView />
        }
      ]
    },
    {
      path: '/reports',
      component: <Outlet />,
      roles: ['Admin', 'HR', 'Accountant'],
      children: [
        {
          index: true,
          path: 'viewReports',
          component: <ReportsPage />,
          roles: ['Admin', 'HR', 'Accountant']
        },
        {
          path: 'vacations',
          component: (
            <ReportsFiltersProvider>
              <ReportsDetailsPage />
            </ReportsFiltersProvider>
          ),
          roles: ['Admin', 'HR', 'Accountant']
        },
        {
          path: 'personalLeaves',
          component: (
            <ReportsFiltersProvider>
              <ReportsDetailsPage />
            </ReportsFiltersProvider>
          ),
          roles: ['Admin', 'HR', 'Accountant']
        }
      ]
    },
    {
      path: '/settings',
      component: <Outlet />,
      roles: ['Admin'],
      children: [
        {
          index: true,
          path: 'ticketingCategories',
          component: <SettingsPage />,
          roles: ['Admin']
        },
        {
          path: 'viewCategory',
          component: <ViewCategoryById />,
          roles: ['Admin']
        },
        {
          path: 'viewWorkQueue',
          component: <ViewWorkQueue />,
          roles: ['Admin']
        },
      ]
    }
  ],
  common: [
    {
      path: '/redirect',
      component: (
        <ErrorBoundary>
          <AuthRedirect />
        </ErrorBoundary>
      )
    },
    { path: '/logout', component: <LogoutRedirect /> },
    {
      path: '*',
      component: <h1>404 page *</h1>
    }
  ]
};
