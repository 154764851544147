import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAuth } from 'services/providers/AuthProvider';
import { useDebouncedCallback } from 'use-debounce';

import {
  useIntlContext,
  useMobileContext
} from 'AppProvider/ConfigProviderSettings';
import ModalComponent from 'components/Modal';
import TimeOffRequestForm from 'components/TimeManagement/TimeOffRequest/TimeOffRequestForm';
import StateActionsForm from 'components/TimeManagement/TimeOffRequest/TimeOffRequestTable/StateActionsForm';
import { useDispatch } from 'react-redux';
import { setColleaguesMonthEvents } from 'redux/slices/colleaguesMonthEventsSlice';
import { TimeSheetService } from 'services';
import { IEmployee } from 'services/providers/TimeSheetProvider/types';
import _sort from 'utils/sorter';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  rolesAllow
} from 'utils/userManagement';
import { colleagueRequestModalProps } from '../ColleaguesTimeOffRequests/types';
import { VacationTable } from './VacationTable/vacationTable';
import VacationToolbar from './VacationToolbar';
import './index.less';

export const VacationModule = () => {
  const { user } = useAuth();
  const [modalProps, setModalProps] = useState<any>(colleagueRequestModalProps);
  const { locale } = useIntlContext();
  const [dataSource, setDataSource] = useState<IEmployee[]>([]);
  const [status, setStatus] = useState('pending');
  const [dateValue, setDateValue] = useState<string>('');

  const userRole = getCurrentUserRole(user);
  const isAdminUserRole = allowPermision(userRole, rolesAllow);

  const currentDate = dayjs();

  const currentMonth = currentDate.format('MMMM');
  const currentDateObj = currentDate;
  const currentYear = currentDate.year();
  const [months, setMonths] = useState(dayjs.months());

  const [dateOfMonth, setDateOfMonth] = useState({
    month: currentMonth,
    date: currentDateObj,
    year: currentYear
  });

  const dispatch = useDispatch();
  const defaultPresortedKey = 'lastName';

  useEffect(() => {
    dayjs.locale(locale);
    const months = dayjs.months();
    setMonths(months);
  }, [locale]);

  const sortDataASC = (data, isAdminUserRole) => {
    return [...data].sort((b, a) =>
      _sort(b[defaultPresortedKey], a[defaultPresortedKey], 'string')
    );
  };

  const debounced = useDebouncedCallback(
    (value: string) => {
      setDateValue(value);
    },
    1000 // 1 second delay
  );

  useEffect(() => {
    // Call debounced function with the formatted date string
    const formattedDate = dayjs(dateOfMonth.date).format('YYYY-MM-DDTHH:mm:ss');
    debounced(formattedDate);
  }, [dateOfMonth.date, debounced]); // Include `debounced` in the dependency array

  useEffect(() => {
    const fetchEvents = () => {
      setStatus('pending');
      TimeSheetService.getColleaguesMonthEvents(
        dayjs(dateOfMonth.date).format('YYYY-MM-DDTHH:mm:ss')
      )
        .then(result => {
          const { data, error } = result;
          if (error) {
            console.error(error);
            setStatus('error');
            return;
          }
          if (data) {
            const employees = data?.employees; // Extract values from the data object
            setDataSource(sortDataASC(employees, isAdminUserRole));
            dispatch(setColleaguesMonthEvents(data));
            setStatus('success');
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setStatus('error');
        });
    };


    if (dateValue) {
      fetchEvents(); // Initial fetch
      const intervalId = setInterval(fetchEvents, 20 * 60 * 1000); // Set interval to 20 minutes

      return () => clearInterval(intervalId); // Clear interval on unmount
    }
  }, [dateValue, isAdminUserRole, dispatch]);

  const { isMobile, isTabletDevice } = useMobileContext();

  return (
    <>
      {isMobile ? (
        <ModalComponent
          formKeyId={modalProps.formKeyId}
          children={
            modalProps.formKeyId === 'timeOffForm' ? (
              <TimeOffRequestForm
                key="timeOffRequestForm"
                requestType={modalProps.requestType}
                scope={modalProps.scope}
                timeOffId={modalProps.timeOffId}
              />
            ) : (
              <StateActionsForm
                key="stateActionForm"
                requestId={modalProps.timeOffId}
                modalProps={modalProps}
              />
            )
          }
          submitBtnText={modalProps.submitBtnText}
          cancelBtnText={modalProps.cancelBtnText}
          additionalBtnText={modalProps.additionalBtnText}
          displayFooterAdditionalButton={
            modalProps.displayFooterAdditionalButton
          }
          title={modalProps.modalTitle}
          displayFooterSubmitButton={modalProps.displayRequestButton}
        />
      ) : (
        <ModalComponent
          formKeyId={modalProps.formKeyId}
          children={
            modalProps.formKeyId === 'timeOffForm' ? (
              <TimeOffRequestForm
                key="timeOffRequestForm"
                requestType={modalProps.requestType}
                scope={modalProps.scope}
                timeOffId={modalProps.timeOffId}
                setModalProps={setModalProps}
              />
            ) : (
              <StateActionsForm
                key="stateActionForm"
                requestId={modalProps.timeOffId}
                modalProps={modalProps}
              />
            )
          }
          submitBtnText={modalProps.submitBtnText}
          cancelBtnText={modalProps.cancelBtnText}
          additionalBtnText={modalProps.additionalBtnText}
          displayFooterAdditionalButton={
            modalProps.displayFooterAdditionalButton
          }
          title={modalProps.modalTitle}
          displayFooterSubmitButton={modalProps.displayRequestButton}
        />
      )}
      <div className="vacationLayout">
        <VacationToolbar
          user={user}
          adminRoles={adminRoles}
          setModalProps={setModalProps}
        />
        <br />
        <VacationTable
          setDateOfMonth={setDateOfMonth}
          dateOfMonth={dateOfMonth}
          months={months}
          data={dataSource}
          status={status}
        />
      </div>
    </>
  );
};

export default VacationModule;
